import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles/index';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import IconButton from "@material-ui/core/IconButton";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import {FieldsJs} from '../../../../settings/General/General';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {ReactLocalStorageService} from '../../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import {
	AccountBalanceOutlined,
	AttachMoneyOutlined,
	CallMadeOutlined,
	HighlightOff
} from "@material-ui/icons";
import $State from "../../../../settings/$State/$State";
import {withRouter} from "react-router-dom";
import icon_mas from '../../../../assets/img/icons/expandir-mas.svg';
import icon_menos from '../../../../assets/img/icons/expandir-menos.svg';
import icon_p from '../../../../assets/img/icons/icon-pre-cotizacion.svg';
import icon_i from '../../../../assets/img/icons/icon-interno.svg';
import icon_f from '../../../../assets/img/icons/icon-factura.svg';
import icon_c from '../../../../assets/img/icons/icon-cotizacion.svg';
import icon_n from '../../../../assets/img/icons/icon-nota-sencilla.svg';
import circulo_a from '../../../../assets/img/icons/circulo-aprobado.svg';
import circulo_n from '../../../../assets/img/icons/circulo-negado.svg';
import Typography from "@material-ui/core/Typography";
import icon_ticket from "../../../../assets/img/icons/icon-ticket.svg";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import icon_xml from '../../../../assets/img/icons/xml.svg';
import icon_pdf from '../../../../assets/img/icons/pdf.svg';
import ModalCancelarCFDI from "./ModalCancelarCFDI";
import {CONFIG} from "../../../../settings/Config/Config";
import Button from "@material-ui/core/Button";


const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 700,
	},
});

class ListaTablaFacturas extends Component {
	
	Usr = {};
	
	state = {};
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			open: false,
		}
	}
	
	openModal = () => {
		this.setState({open: true});
	}
	/*openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			},
		});
		console.log(this.state, props);
	};
	*/
	closeModal = () => {
		this.setState({
			open: false
		});
	};
	
	detalles = (item) => {
		$State.go(this.props, 'ordendeventa', {id_venta: item.id_venta})
	};
	
	editar = (item) => {
		$State.go(this.props, 'ordendeventa', {editar: 'editar', id_venta: item.id_venta})
	};
	
	cancelar = (item) => {
		this.props.cancelar(item);
	};
	
	activar = (item) => {
		this.props.activar(item);
	};
	
	ver_root = () => {
		return this.Usr.id_usuario === 1;
	};
	
	ticket_de_entrega = (value) => {
		HttpRequest.export('texid', ['ticket'], {
			id_venta_producto: value.id_venta_producto
		}, true, 450, 680)
	};
	
	is_cotizacion = (item) => {
		return item.cotizacion === 1 && item.factura === 0 && item.nota_sencilla === 0 && item.interno === 0;
	};
	
	is_cancel = (item) => {
		return item.cancelado === 1;
	};
	
	is_edited = (item) => {
		return FieldsJs.inArray([1, 2], Number(item.id_cat_venta_status_proceso)) || (item.edicion === "Aprobado" || item.edicion === "Forzar edicion");
	};
	
	crear_array_list = (item) => {
		
		let item_list = [];
		
		if (item.factura === 1) {
			item_list.push({
				id: 1,
				name: 'desactivar_factura',
				label: 'Desactivar el cliente requiere una factura'
			});
		}
		
		if (item.nota_sencilla === 1) {
			item_list.push({
				id: 2,
				name: 'desactivar_nota_sencilla',
				label: 'Desactivar nota sencilla'
			});
		}
		
		if (item.interno === 1) {
			item_list.push({
				id: 3,
				name: 'desactivar_interno',
				label: 'Desactivar orden de tipo interno'
			});
		}
		
		if (FieldsJs.inArray([3, 4], Number(item.id_cat_venta_status_proceso)) && item.edicion === "Sin accion") {
			item_list.push({
				id: 4,
				name: 'solicitar_edicion',
				label: 'Solicitar edición'
			});
		}
		
		if (item.edicion === "Solicitar") {
			item_list.push({
				id: 6,
				name: 'cancelar_solicitud_de_edicion',
				label: 'Cancelar solicitud de edición'
			});
		}
		
		if (FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario)) {
			
			if (FieldsJs.inArray([3, 4], Number(item.id_cat_venta_status_proceso)) && item.edicion === "Solicitar") {
				item_list.push({
					id: 5,
					name: 'aprobar_edicion',
					label: 'Aprobar edición'
				});
			}
			
			if ((item.edicion === "Aprobado" || item.edicion === "Forzar edicion")) {
				item_list.push({
					id: 7,
					name: 'cancelar_edicion',
					label: 'Cancelar edición'
				});
			}
			
		}
		
		if ((item.edicion === "Aprobado" || item.edicion === "Forzar edicion")) {
			item_list.push({
				id: 7,
				name: 'finalizar_edicion',
				label: 'Finalizar edición'
			});
		}
		
		return item_list;
	};
	
	status_lista_click_derecho = (item) => {
		let status = [];
		if (item.factura === 1) {
			status.push(1)
		}
		if (item.nota_sencilla === 1) {
			status.push(2)
		}
		if (item.interno === 1) {
			status.push(3)
		}
		return status.length > 1;
	};
	
	exportarFacturacion = (link, tipo) => {
		if (tipo === 'PDF') {
			try {
				let params = {
					id_proyecto: 3,
					archivo: tipo,
					archivo_xml: link
				};
				HttpRequest.exportFacturacion('xmltopdf', ['xmltopdf'], params, false, false, false, true);
			} catch (e) {
				this.showSnackBars('error', e.mensaje);
			}
		} else {
			let url = CONFIG.src_facturacion + link;
			window.open(url, '', 'width=900, height=600');
		}
	};
	
	render() {
		
		const textoClaseIcono = "bg-img-contain-x-center-y-center h-15-px w-15-px cursor-pointer margin-2-R margin-2-L";
		
		const {classes, lista} = this.props;
		
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		
		const bg_p = {
			backgroundImage: `url(${icon_p})`
		};
		
		const bg_i = {
			backgroundImage: `url(${icon_i})`
		};
		
		const bg_f = {
			backgroundImage: `url(${icon_f})`
		};
		
		const bg_c = {
			backgroundImage: `url(${icon_c})`
		};
		
		const bg_n = {
			backgroundImage: `url(${icon_n})`
		};
		
		const bg_circulo_a = {
			backgroundImage: `url(${circulo_a})`
		};
		
		const bg_circulo_n = {
			backgroundImage: `url(${circulo_n})`
		};
		
		const bg_icon_ticket = {
			backgroundImage: `url(${icon_ticket})`
		};
		
		const bg_carta = {
			backgroundImage: `url(${icon_xml})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain'
		};
		
		const bg_ticket = {
			backgroundImage: `url(${icon_pdf})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain'
		};
		
		return (<Paper className={classes.root}>
			<Table className={classes.table + ' desing-acresco'}>
				<TableHead>
					<TableRow>
						<TableCell component="th" align={'left'} className={'padding-10-L padding-10-R'}>
							UUID
						</TableCell>
						<TableCell component="th" align={'center'}>Serie</TableCell>
						<TableCell component="th" align={'center'}>Folio</TableCell>
						<TableCell component="th" align={'left'}>Total</TableCell>
						<TableCell component="th" align={'center'}>Fecha</TableCell>
						<TableCell component="th" align={'left'}>Estatus</TableCell>
						<TableCell component="th" align={'center'}>CFDI</TableCell>
						<TableCell component="th" align={'center'}>Acciones</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{lista.map((item, key) => (
						<Fragment key={key}>
							<TableRow key={key}>
								
								<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<p className={'w-100 padding-0 margin-0 ff-arial'}>
										{item.uuid}
									</p>
								</TableCell>
								
								<TableCell align={'right'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<p className={'w-100 padding-0 margin-0 ff-arial'}>
										{item.serie}
									</p>
								</TableCell>
								
								<TableCell align={'right'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<p className={'w-100 padding-0 margin-0 ff-arial'}>
										{item.folio}
									</p>
								</TableCell>
								
								<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<p className={'w-100 padding-0 margin-0 ff-arial'}>
										${FieldsJs.Currency(item.total)}
									</p>
								</TableCell>
								
								<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<p className={'w-100 padding-0 margin-0 ff-arial'}>
										{DateFormat.FormatText(item.fecha)}
									</p>
								</TableCell>
								
								<TableCell align={'left'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<td align={'left'}>
										<Typography className={'px-12 text-left'}>
											{item.cancelado ? "Cancelado":"Vigente"}
										</Typography>
									</td>
								</TableCell>
								
								<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<div className={'text-center'}>
										<div className={'vertical-inline'}>
											<div className={'w-30-px v-center margin-15-R'}>
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver XML">
													<div style={bg_carta} className={'h-25-px'} onClick={() => this.exportarFacturacion(item.archivo_xml)}/>
												</Tooltip>
											</div>
											<div className={'w-30-px v-center'}>
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver PDF">
													<div style={bg_ticket} className={'h-25-px'} onClick={() => this.exportarFacturacion(item.archivo_xml, 'PDF')}/>
												</Tooltip>
											</div>
										</div>
									</div>
								</TableCell>
								
								<TableCell align={'center'} className={this.is_cancel(item) ? 'text-tachado' : ''}>
									<div className={'text-center'}>
										<ModalCancelarCFDI
											item={item}
											tipo={'add'}
											project={2}
											componente={(
												<Button
													variant="contained"
													size={'small'}
													color="default"
													startIcon={<HighlightOff/>}
												>
													Cancelar
												</Button>
											)}
											RefreshList={this.props.RefreshList}
											showSnackBars={this.props.showSnackBars}
											history={this.props.history}
										/>
									</div>
								</TableCell>
							
							
							</TableRow>
						
						</Fragment>
					))}
				</TableBody>
			</Table>
		</Paper>)
	}
}

ListaTablaFacturas.propTypes = {
	classes: PropTypes.object.isRequired,
	lista: PropTypes.array.isRequired,
	exportar: PropTypes.func.isRequired,
	exportarTicket: PropTypes.func.isRequired,
	modalDisenador: PropTypes.func.isRequired,
	// almacen: PropTypes.func.isRequired,
	// entregar: PropTypes.func.isRequired,
	cancelar: PropTypes.func.isRequired,
	activar: PropTypes.func.isRequired,
	produccion: PropTypes.func.isRequired,
	OrdenDeVentaContinuar: PropTypes.func.isRequired,
	
	watchHorasTrabajadas: PropTypes.func.isRequired,
	watchAsignarCliente: PropTypes.func.isRequired,
	watchEnviarTodoAlmacen: PropTypes.func.isRequired,
	watchEnviarTodoCliente: PropTypes.func.isRequired,
	watchEnviarAlmacen: PropTypes.func.isRequired,
	watchEnviarCliente: PropTypes.func.isRequired,
	watchAprobarCotizacion: PropTypes.func.isRequired,
	
	handleChange: PropTypes.func.isRequired,
	SubTabla: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	DesactivarFacturaNotaSencillaInterno: PropTypes.func.isRequired,
	listDropbox: PropTypes.array
};

export default withStyles(styles)(withRouter(ListaTablaFacturas));
