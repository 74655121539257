import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import TextField from "@material-ui/core/TextField";
import {TimbradoFacturacionExtService} from "../../../../services/TimbradoFacturacionExtService/TimbradoFacturacionExtService";
import {DialogActions} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import {Send, Visibility} from "@material-ui/icons";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import {EmpresaFacturacionExtService} from "../../../../services/EmpresaFacturacionExtService/EmpresaFacturacionExtService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import icon_xml from "../../../../assets/img/icons/xml.svg";
import icon_pdf from "../../../../assets/img/icons/pdf.svg";
import icon_logo from "../../../../assets/img/logo.svg";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import {VentasService} from "../../../../services/VentasService/VentasService";


class ModalPago extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false,
				open2: false
			},
			
			tipo: props.tipo,
			
			id_facturacion_cfdi: props.id_facturacion_cfdi || '',
			id_venta: props.id_venta || '',
			id_venta_pago: props.id_venta_pago || '',
			enviar_cc: '',
			enviar_cco: '',
			fecha_pago: null,
			id_cat_forma_pago: '',
			monto: '',
			rfc_emisor_cuenta_ord: '',
			banco: '',
			rfc_emisor_cuenta_ben: '',
			cuenta_beneficiario: '',
			numero_parcialidad: '',
			importe_saldo_anterior: '',
			importe_pagado: '',
			importe_saldo_insoluto: '',
			id_cat_objeto_impuesto: '',
			listar_cat_objeto_impuesto: [],
			listar_cat_forma_pago: [],
			id_empresa_sucursal_serie: '',
			listar_empresa_sucursal_serie: [],
			folio: '',
			serie: '',
			id_cliente: (props.item.cliente || '').id_cliente,
			enviar_a: (props.item.cliente || '').correo_electronico,
			cliente_regimen_fiscal: (props.item.cliente || '').id_cat_regimen_fiscal,
			cliente_numero_registro_if: (props.item.cliente || '').registro_identidad_fiscal,
			cliente_codigo_postal: (props.item.cliente || '').codigo_postal,
			cliente_rfc: (props.item.cliente || '').rfc,
			cliente_nombre_razon_social: (props.item || '').cliente_nombre_completo,
			fecha: '1',
			
		};
		console.log("PROPS.CLIENTE  >>>>>>>>> ////",props.item);
	}
	
	ListaEmpresaSucursal = (id_empresa) => {
		
		let empresas = FieldsJs.Copy(this.state.listar_empresa);
		for (let i = 0; i < empresas.length; i++) {
			if (Number(id_empresa) === Number(empresas[i].id_empresa)) {
				this.setState({
					rfc_emisor: empresas[i].rfc,
				});
			}
		}
		
		if (FieldsJs.Field(id_empresa)) {
			CatService.ListEmpresaSucursalFac(id_empresa, {}).then((response) => {
				if (response.data.length > 0) {
					this.setState({
						listar_empresa_sucursal: response.data,
						id_empresa_sucursal: response.data[0].id_empresa_sucursal,
						lugar_expedicion: response.data[0].codigo_postal,
						sucursal_emisor: response.data[0].nombre,
					});
					this.ListaEmpresaSucursalSerie(response.data[0].id_empresa_sucursal);
				} else {
					this.setState({
						listar_empresa_sucursal: response.data,
					});
				}
			}).catch((error) => {
				this.setState({
					listar_empresa_sucursal: []
				});
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			this.setState({
				listar_empresa_sucursal: []
			});
		}
	};
	
	ListaFormaPago = () => {
		CatService.ListFormaPagoFac({}, {}).then((response) => {
			this.setState({
				listar_cat_forma_pago: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_forma_pago: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	
	ListaObjetoImpuesto = () => {
		CatService.ListObjetoImpuestoFac({}, {}).then((response) => {
			this.setState({
				listar_cat_objeto_impuesto: response.data,
				id_cat_objeto_impuesto: 2,
			});
		}).catch((error) => {
			this.setState({
				listar_cat_objeto_impuesto: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	handleChangeSerie = (id_empresa_sucursal_serie, valor) => {
		let series = FieldsJs.Copy(this.state.listar_empresa_sucursal_serie);
		for (let i = 0; i < series.length; i++) {
			if (Number(id_empresa_sucursal_serie) === Number(series[i].id_empresa_sucursal_serie)) {
				this.setState({
					serie: series[i].serie
				});
			}
		}
		
		EmpresaFacturacionExtService.SerieFolio(id_empresa_sucursal_serie).then((response) => {
			this.setState({
				folio: response.data
			});
		}).catch((error) => {
			this.setState({listar_facturacion: []});
			this.showSnackBars('error', error.mensaje);
		});
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true,
				id_facturacion_cfdi: props.id_facturacion_cfdi || '',
				id_venta: props.id_venta || '',
				id_venta_pago: props.id_venta_pago || '',
			},
		});
		console.log("openModal1:::",this.state, props);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	/*open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};*/
	
	close = () => {
		this.closeModal();
	};
	
	openModal2 = () => {
		const props = this.props;
		this.setState({
			modal: {
				open2: true
			},
		});
		console.log(this.state, props);
	};
	
	closeModal2 = () => {
		this.setState({
			modal: {
				open2: false
			}
		});
	};
	
	open2 = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal2());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close2 = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	ConsultaCFDIProyecto = () => {
		return new Promise((resolve, reject) => {
			TimbradoFacturacionExtService.ConsultaPagoCFDIProyecto(this.state).then((response) => {
				resolve(response);
				//this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.setState({listar_facturacion: []});
				//this.props.showSnackBars('error', error.mensaje);
				reject(error);
			});
		});
	}
	
	handleChangeEmpresa = (id_empresa) => {
		console.log(id_empresa);
		this.setState({
			id_empresa: id_empresa
		});
		this.ListaEmpresaSucursal(id_empresa);
		/*this.ListaEmpresaRegimenFiscal(id_empresa);
		this.ListaConcepto(id_empresa);
		this.ListaUnidadMedida(id_empresa);
		this.ListaMoneda(id_empresa);*/
		
	}
	
	add = () => {
		let {item, pago, id_facturacion_cfdi, id_venta, id_venta_pago} = this.props;
		
		this.ListaFormaPago();
		this.ListaObjetoImpuesto();
		
		this.setState({
			id_facturacion_cfdi: id_facturacion_cfdi || '',
			id_venta: id_venta || '',
			id_venta_pago: id_venta_pago || '',
			enviar_a: (item.cliente || '').correo_electronico,
			enviar_cc: '',
			enviar_cco: '',
			fecha_pago: pago.fecha_alta ? moment(pago.fecha_alta) : null,
			id_cat_forma_pago: pago.id_cat_forma_pago || '',
			monto: pago.monto || '',
			importe_pagado: pago.monto || '',
			rfc_emisor_cuenta_ord: '',
			banco: '',
			rfc_emisor_cuenta_ben: 'BBA940707IE1',
			cuenta_beneficiario: '030222900019085278',
			numero_parcialidad: '',
			importe_saldo_anterior: '',
			id_cat_objeto_impuesto: '',
			importe_saldo_insoluto: '',
			fecha: '1',
			
		});
		
		this.open();
	};
	
	open = () => {
		const {item, cliente} = this.props;
		console.log("item ::::: >>>", item, item.cliente);
		console.log("PROPS.CLIENTE>>>>>>>>>", cliente);
		if (FieldsJs.Field(cliente)) {
			this.setState({
				id_cliente: cliente.id_cliente,
				enviar_a: cliente.correo_electronico,
				cliente_regimen_fiscal: cliente.id_cat_regimen_fiscal,
				cliente_numero_registro_if: cliente.registro_identidad_fiscal,
				cliente_codigo_postal: cliente.codigo_postal,
				cliente_rfc: cliente.rfc,
				cliente_nombre_razon_social: Number(cliente.id_cat_tipo_persona) === 1 ? cliente.nombre_completo:cliente.razon_social,
			});
		}
		
		if (!item.cliente.receptor_factura && !item.timbrado_cfdi) {
			let msg = `El cliente no cuenta con los datos suficientes para realizar la factura. Favor de completar sus datos.`;
			
			PopupService.Confirm(['Cancelar', 'Ir a Clientes'], 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
				
				if (r.button === 'Ir a Clientes') {
					this.props.history.push('/clientes');
				}
				
			});
		} else {
			
			this.setState({
				nombre_razon_social: Number(item.cliente.id_cat_tipo_persona) === 1 ? item.cliente.rfc + " - " + item.cliente.nombre + " " + item.cliente.apellido_paterno + " " + item.cliente.apellido_materno : item.cliente.razon_social,
				partidas: [],
			})
			
			this.ConsultaCFDIProyecto().then((response) => {
				console.log("RESPONSE 11::: ", response);
				this.setState({
					archivo_xml: response.data.archivo_xml || '',
					//id_facturacion_cfdi: response.data.id_facturacion_cfdi || '',
				});
				
				this.init().then(r => {
					setTimeout(() => this.openModal2());
				}).catch(e => {
					console.log(e);
				});
			}).catch((error) => {
				console.log("ERROR::: ", error);
				
				let peticiones = new Promise((resolve) => {
					this.ListaEmpresa().then((response) => {
						this.handleChangeEmpresa(11);
					});
					this.ListaCliente();
					
					setTimeout(() => {
						resolve(true);
					}, 1300);
				});
				
				this.init().then(r => {
					setTimeout(() => this.openModal());
				}).catch(e => {
					console.log(e);
				});
			});
		}
	};
	
	ListaEmpresa = () => {
		return new Promise((resolve, reject) => {
			CatService.ListEmpresaFac({}, {}).then((response) => {
				this.setState({
					listar_empresa: response.data,
				});
				resolve(response.data);
			}).catch((error) => {
				this.setState({
					listar_empresa: []
				});
				reject(error);
				this.props.showSnackBars('error', error.mensaje);
			});
		});
	};
	
	ListaCliente = () => {
		CatService.ListClienteFac({}, {}).then((response) => {
			this.setState({
				listar_cliente: response.data,
			});
		}).catch((error) => {
			this.setState({
				listar_cliente: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	pagoSat = () => {
		try {
			console.log(this.state);
			if (!this.state.fecha_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Fecha Pago.'
				})
			}
			if (!this.state.id_cat_forma_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Forma de Pago.'
				})
			}
			if (!this.state.monto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Monto.'
				})
			}
			if (!this.state.id_cat_objeto_impuesto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Objeto de Impuesto.'
				})
			}
			/*if (Number(this.state.id_cat_forma_pago) !== 1 && !this.state.rfc_emisor_cuenta_ord) {
				throw Object({
					status: false, mensaje: 'Campo requerido: RFC Emisor Cuenta Orden.'
				})
			}
			if (Number(this.state.id_cat_forma_pago) !== 1 && !this.state.rfc_emisor_cuenta_ben) {
				throw Object({
					status: false, mensaje: 'Campo requerido: RFC Emisor Cuenta Beneficiario.'
				})
			}
			if (Number(this.state.id_cat_forma_pago) !== 1 && !this.state.cuenta_beneficiario) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cuenta Beneficiario.'
				})
			}*/
			if (!this.state.numero_parcialidad) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Número Parcialidad.'
				})
			}
			if (!this.state.importe_saldo_anterior) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Importe Saldo Anterior.'
				})
			}
			if (!this.state.importe_pagado) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Importe Pagado.'
				})
			}
			if (!this.state.serie) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Serie.'
				})
			}
			if (!this.state.folio) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Folio.'
				})
			}
			
			
			/*TimbradoFacturacionExtService.Agregar(this.state).then((response) => {
				
				//this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.setState({listar_facturacion: []});
				this.props.showSnackBars('error', error.mensaje);
			});*/
			
			TimbradoFacturacionExtService.PagoCFDI(this.state).then((response) => {
				let msg = `${response.data.code} - ${response.data.message}`;
				PopupService.Confirm(['Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
					if (r.button === 'Aceptar') {
						/*this.setState({
							ruta: response.ruta,
							open_modal_xml: true
						});*/
						if (Number(response.data.code) === 200 || Number(response.data.code) === 307) {
							
							VentasService.Timbrado_CFDI_Pago(this.state).then((response) => {
								this.props.RefreshList();
								this.closeModal();
								this.props.showSnackBars('success', response.mensaje);
							}).catch((error) => {
								this.setState({listar_facturacion: []});
								this.props.showSnackBars('error', error.mensaje);
							});
						}
					}
					if (Number(response.data.code) === 200 || Number(response.data.code) === 307) {
						this.closeModal();
					}
				});
				/*
				this.props.showSnackBars('success', response.mensaje);
				this.closeModal();
				this.props.RefreshList();*/
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} catch (e) {
			console.log(this.state);
			this.props.showSnackBars('error', e.mensaje, e);
		}
	}
	
	obtenerSaldoInsoluto = () => {
		let imp_ant = FieldsJs.Field(this.state.importe_saldo_anterior) ? Number(this.state.importe_saldo_anterior) : 0;
		let imp_pag = FieldsJs.Field(this.state.importe_pagado) ? Number(this.state.importe_pagado) : 0;
		
		this.setState({
			importe_saldo_insoluto: (imp_ant - imp_pag)
		});
	}
	
	ListaEmpresaSucursalSerie = (id_empresa_sucursal) => {
		EmpresaFacturacionExtService.Series(id_empresa_sucursal, {}).then((response) => {
			if (response.data.length > 0) {
				this.setState({
					listar_empresa_sucursal_serie: response.data,
					id_empresa_sucursal_serie: response.data[0].id_empresa_sucursal_serie,
					serie: response.data[0].serie,
				});
				this.handleChangeSerie(response.data[0].id_empresa_sucursal_serie);
			} else {
				this.setState({
					listar_empresa_sucursal_serie: response.data,
					id_empresa_sucursal_serie: '',
				});
			}
		}).catch((error) => {
			this.setState({
				listar_cat_moneda: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	exportarFacturacion = (link, tipo) => {
		if (tipo === 'PDF') {
			try {
				let params = {
					id_proyecto : 2,
					archivo: tipo,
					archivo_xml: link
				};
				HttpRequest.exportFacturacion('xmltopdfp', ['xmltopdfp'], params, false, false, false, true);
			} catch (e) {
				this.showSnackBars('error', e.mensaje);
			}
		} else {
			let url = CONFIG.src_facturacion + link;
			window.open(url, '', 'width=900, height=600');
		}
	};
	
	visualizacionPDF = () => {
		try {
			
			if (!this.state.fecha_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Fecha de pago.'
				})
			}
			if (!this.state.id_cat_forma_pago) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Forma de pago.'
				})
			}
			if (!this.state.monto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Monto.'
				})
			}
			if (!this.state.id_cat_objeto_impuesto) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Objeto de impuesto.'
				})
			}
			if (Number(this.state.id_cat_forma_pago) !== 1 && !this.state.rfc_emisor_cuenta_ord) {
				throw Object({
					status: false, mensaje: 'Campo requerido: RFC emisor cuenta ord.'
				})
			}
			if (Number(this.state.id_cat_forma_pago) !== 1 && !this.state.rfc_emisor_cuenta_ben) {
				throw Object({
					status: false, mensaje: 'Campo requerido: RFC emisor cuenta beneficiario.'
				})
			}
			if (Number(this.state.id_cat_forma_pago) !== 1 && !this.state.cuenta_beneficiario) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cuenta beneficiario.'
				})
			}
			if (!this.state.numero_parcialidad) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Número de parcialidad.'
				})
			}
			if (!this.state.importe_saldo_anterior) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Importe saldo anterior.'
				})
			}
			if (!this.state.importe_pagado) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Importe pagado.'
				})
			}
			if (!this.state.serie) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Serie.'
				})
			}
			if (!this.state.folio) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Folio.'
				})
			}
			
			
			let params = {
				id_facturacion_cfdi: this.state.id_facturacion_cfdi,
				id_proyecto: 3,
				id_venta: this.state.id_venta,
				fecha_pago: moment(new Date(this.state.fecha_pago)).format('DD/MM/YYYY hh:mm:ss a').toString(),
				id_cat_forma_pago: this.state.id_cat_forma_pago,
				monto: this.state.monto,
				rfc_emisor_cuenta_ord: this.state.rfc_emisor_cuenta_ord,
				banco: this.state.banco,
				rfc_emisor_cuenta_ben: this.state.rfc_emisor_cuenta_ben,
				cuenta_beneficiario: this.state.cuenta_beneficiario,
				numero_parcialidad: this.state.numero_parcialidad,
				importe_saldo_anterior: this.state.importe_saldo_anterior,
				importe_pagado: this.state.importe_pagado,
				id_cat_objeto_impuesto: this.state.id_cat_objeto_impuesto,
				importe_saldo_insoluto: this.state.importe_saldo_insoluto,
				enviar_a: this.state.enviar_a,
				enviar_cc: this.state.enviar_cc,
				enviar_cco: this.state.enviar_cco,
				id_empresa: this.state.id_empresa || '',
				id_cliente: this.state.id_cliente || '',
				id_empresa_sucursal: this.state.id_empresa_sucursal || '',
				serie: this.state.serie || '',
				folio: this.state.folio || '',
				id_cat_regimen_fiscal: this.state.id_cat_regimen_fiscal || '',
				lugar_expedicion: this.state.lugar_expedicion || '',
				cliente_regimen_fiscal: this.state.cliente_regimen_fiscal || '',
				cliente_numero_registro_if: this.state.cliente_numero_registro_if || '',
				cliente_codigo_postal: this.state.cliente_codigo_postal || '',
				cliente_rfc: this.state.cliente_rfc || '',
				cliente_nombre_razon_social: this.state.cliente_nombre_razon_social || '',
			};
			
			console.log("Generar pdf", params);
			
			HttpRequest.exportFacturacion('pdfvisualizacionpago', ['pdfvisualizacionpago'], params, true, 800, 1000, true);
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	render() {
		
		const bg_carta = {
			backgroundImage: `url(${icon_xml})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain'
		};
		
		const bg_ticket = {
			backgroundImage: `url(${icon_pdf})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain'
		};
		
		const icon_ = {
			backgroundImage: `url(${icon_logo})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'contain',
			opacity: '0.4'
		};
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'add':
				funcion = this.add;
				break;
			
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.component ? (
					<Fragment>
						<span onClick={() => this.add()} style={{cursor: 'pointer'}}>
							{this.props.component}
						</span>
					</Fragment>
				) : (
					<Fragment>
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={() => this.openModal('paper')}>
							<Send className={'margin-5-R px-14'}/>
							Enviar Correo Electrónico
						</Fab>
					</Fragment>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Recibo Electrónico de Pago
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} alignItems={"flex-end"} alignContent={"flex-end"}>
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<KeyboardDateTimePicker
									format="yyyy/MM/dd hh:mm a"
									fullWidth
									required
									clearable
									inputProps={{readOnly: true}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha de Pago',
									}}
									label="Fecha de Pago"
									value={this.state.fecha_pago}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_pago');
									}}
									// minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_forma_pago"
									label="Forma de Pago"
									value={this.state.id_cat_forma_pago}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_forma_pago.map(option => (
										<option key={option.id_cat_forma_pago} value={option.id_cat_forma_pago}>
											{option.descripcion ? option.forma_pago + " - " + option.descripcion : option.forma_pago}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									margin="none"
									name="monto"
									label="Monto"
									type="text"
									fullWidth
									value={this.state.monto}
									onChange={(e) => {
										this.handleChange(e);
									}}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									select
									fullWidth
									required
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_cat_objeto_impuesto"
									label="Objeto de Impuesto"
									value={this.state.id_cat_objeto_impuesto}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_cat_objeto_impuesto.map(option => (
										<option key={option.id_cat_objeto_impuesto} value={option.id_cat_objeto_impuesto}>
											{option.descripcion ? option.objeto_impuesto + ' - ' + option.descripcion : option.objeto_impuesto}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									name="rfc_emisor_cuenta_ord"
									label="RFC Emisor Cuenta Ord: "
									type="text"
									fullWidth
									value={this.state.rfc_emisor_cuenta_ord}
									onChange={this.handleChange}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									name="banco"
									label="Banco"
									type="text"
									fullWidth
									value={this.state.banco}
									onChange={this.handleChange}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									name="rfc_emisor_cuenta_ben"
									label="RFC Emisor Cuenta Beneficiario: "
									type="text"
									fullWidth
									value={this.state.rfc_emisor_cuenta_ben}
									onChange={this.handleChange}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									name="cuenta_beneficiario"
									label="Cuenta Beneficiario"
									type="text"
									fullWidth
									value={this.state.cuenta_beneficiario}
									onChange={this.handleChange}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									name="numero_parcialidad"
									label="Número de Parcialidad"
									type="text"
									fullWidth
									value={this.state.numero_parcialidad}
									onChange={this.handleChange}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									name="importe_saldo_anterior"
									label="Importe Saldo Anterior"
									type="text"
									fullWidth
									value={this.state.importe_saldo_anterior}
									onChange={(e) => {
										this.handleChange(e);
										setTimeout(() => {
											this.obtenerSaldoInsoluto(e);
										}, 800);
									}}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									name="importe_pagado"
									label="Importe Pagado"
									type="text"
									fullWidth
									value={this.state.importe_pagado}
									onChange={(e) => {
										this.handleChange(e);
										setTimeout(() => {
											this.obtenerSaldoInsoluto(e);
										}, 800);
									}}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									required
									name="importe_saldo_insoluto"
									label="Importe Saldo Insoluto"
									type="text"
									fullWidth
									value={this.state.importe_saldo_insoluto}
									onChange={this.handleChange}
									onKeyPress={EnteroSolo}
								/>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<TextField
									required
									select
									fullWidth
									margin="none"
									onChange={(e) => {
										this.handleChange(e);
										this.handleChangeSerie(e.target.value);
									}}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="id_empresa_sucursal_serie"
									label="Serie"
									value={this.state.id_empresa_sucursal_serie}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.listar_empresa_sucursal_serie.map(option => (
										<option key={option.id_empresa_sucursal_serie} value={option.id_empresa_sucursal_serie}>
											{option.serie}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
								<TextField
									required
									margin="none"
									name="folio"
									label="Folio"
									type="text"
									fullWidth
									value={this.state.folio}
									onChange={this.handleChange}
									disabled
								/>
							</Grid>
							
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={(e) => {
										this.handleChange(e);
									}}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									name="fecha"
									label="Fecha de CFDI"
									value={this.state.fecha}
								>
									<option value={'1'}>Timbrar con fecha actual</option>
									<option value={'2'}>Fecha de ayer</option>
									<option value={'3'}>Fecha de hace dos días</option>
									<option value={'4'}>Fecha de hace tres días</option>
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'margin-15-T'}>
								
								<fieldset className={'borde-punteado-gray-2 text-left'}>
									<legend align={'left'} style={{color: 'dimgrey', fontWeight: 'bold'}}> Datos de envío</legend>
									
									<Grid container spacing={2}>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={8} lg={8} xl={8}>
											<TextField
												required
												name="enviar_a"
												label="Enviar a: "
												type="text"
												fullWidth
												value={this.state.enviar_a}
												onChange={this.handleChange}
											/>
										</Grid>
										
										<Grid item xs={12} sm={12} md={4} lg={4} xl={4} align={'right'}>
											<div className={'h-100 vertical-inline'}>
												<div className={'v-center'}>
													<Button onClick={() => this.visualizacionPDF()} color="primary" size={'small'} variant="contained" startIcon={<Visibility/>}>
														{'Previsualizar'}
													</Button>
												</div>
											</div>
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<TextField
												name="enviar_cc"
												label="Cc:"
												type="text"
												fullWidth
												value={this.state.enviar_cc}
												onChange={this.handleChange}
											/>
										
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											<TextField
												name="enviar_cco"
												label="Cco:"
												type="text"
												fullWidth
												value={this.state.enviar_cco}
												onChange={this.handleChange}
											/>
										
										</Grid>
									
									
									</Grid>
								
								</fieldset>
							
							</Grid>
							{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
								<Divider style={{marginTop: "10px", marginBottom: "15px"}}/>
								<div className={'text-right'}>
									<div className={'vertical-inline'}>
										<Button className={'v-center margin-15-R'} color="default" size={'small'} variant="contained" onClick={() => this.handleChangeTabs(null, '2')}>
											{'Cerrar'}
										</Button>
										<Button className={'v-center'} color="primary" size={'small'} variant="contained" onClick={() => this.pagoSat()}>
											{'Generar Pago'}
										</Button>
									</div>
								</div>
							</Grid>*/}
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								<Button onClick={() => this.pagoSat()} color="primary" variant={"contained"}>
									Timbrar Pago
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
				
				
				<Dialog open={this.state.modal.open2} onClose={() => this.close2()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<Typography align={'left'}>Factura Pago Realizada</Typography>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={3}>
							
							<Grid item xs={12}>
								<Typography align={'center'}>Selecciona una opción:</Typography>
							</Grid>
							
							<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
								
								<div className={'borde-punteado-gray-2 br-10 cursor-pointer text-center'} style={{borderRadius: '5%'}} onClick={() => this.exportarFacturacion(this.state.archivo_xml)}>
									<div style={bg_carta} className={'h-70-px margin-15'}/>
									<Typography className={'margin-10'}>
										Descargar XML
									</Typography>
								</div>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
								<div className={'vertical-inline w-100 h-100'}>
									<div className={'w-100 h-100-px'} style={icon_}></div>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
								
								<div className={'borde-punteado-gray-2 br-10 cursor-pointer text-center'} style={{borderRadius: '5%'}} onClick={() => this.exportarFacturacion(this.state.archivo_xml, 'PDF')}>
									<div style={bg_ticket} className={'h-70-px margin-15'}/>
									<Typography className={'margin-10'}>
										Descargar PDF
									</Typography>
								</div>
							
							</Grid>
						
						</Grid>
						
						<br/>
					
					</DialogContent>
					
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close2} color="primary">
								{'Cerrar'}
							</Button>
						</Fragment>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalPago.propTypes = {
	tipo: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.oneOf(['view', 'add', 'edit']).isRequired,
	]),
	id_facturacion_cfdi: PropTypes.number.isRequired,
	cliente: PropTypes.object,
	item: PropTypes.object,
	pago: PropTypes.object,
	componente: PropTypes.element,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalPago;
