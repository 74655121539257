import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import moment from "moment";
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const TimbradoFacturacionExtService = {
	Listar: (filtro, paginacion) => {
		let fecha_inicio = (filtro.fecha_inicio || null) ? DateFormat.FormatSql(filtro.fecha_inicio) : null;
		let fecha_fin = (filtro.fecha_fin || null) ? DateFormat.FormatSql(filtro.fecha_fin) : null;
		
		let params = {
			filtro: {
				folio: filtro.folio || "",
				serie: filtro.serie || "",
				empresa: filtro.empresa || "",
				cliente: filtro.cliente || "PUBLICO EN GENERAL",
				rfc_receptor: "XAXX010101000",
				lugar_expedicion: filtro.lugar_expedicion || "",
				fecha_inicio: fecha_inicio || "",
				fecha_fin: fecha_fin || "",
				id_cat_forma_pago: filtro.id_cat_forma_pago || "",
				id_cat_metodo_pago: filtro.id_cat_metodo_pago || "",
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
				id_proyecto: 3
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Datos', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_venta: form.id_venta || '',
			rfc_emisor: form.rfc_emisor || '',
			sucursal_emisor: form.sucursal_emisor || '',
			certificado_emisor: form.certificado_emisor || '',
			observaciones_emisor: form.observaciones_emisor || '',
			enviar_a: form.enviar_a || '',
			enviar_cc: form.enviar_cc || '',
			enviar_cco: form.enviar_cco || '',
			id_empresa: form.id_empresa || '',
			id_cliente: form.id_cliente || '',
			id_empresa_sucursal: form.id_empresa_sucursal || '',
			id_cat_regimen_fiscal: form.id_cat_regimen_fiscal || '',
			lugar_expedicion: form.lugar_expedicion || '',
			cliente_regimen_fiscal: form.cliente_regimen_fiscal || '',
			cliente_numero_registro_if: form.cliente_numero_registro_if || '',
			partidas: form.partidas || [],
			desglose: form.desglose || '',
			id_cat_tipo_cfdi: form.id_cat_tipo_cfdi || '',
			id_cat_metodo_pago: form.id_cat_metodo_pago || '',
			id_cat_forma_pago: form.id_cat_forma_pago || '',
			id_cat_moneda: form.id_cat_moneda || '',
			id_cat_uso_cfdi: form.id_cat_uso_cfdi || '',
			id_empresa_sucursal_serie: form.id_empresa_sucursal_serie || '',
			fecha: form.fecha || '',
			serie: form.serie || '',
			folio: form.folio || '',
			numero_pedido: form.numero_pedido || '',
			condiciones_pago: form.condiciones_pago || '',
			clave_confirmacion: form.clave_confirmacion || '',
			tipo_cambio: form.tipo_cambio || '',
			produccion: form.produccion ? 1 : 0,
			activo: form.activo ? 1 : 0,
			is_exhibiciones: form.is_exhibiciones ? 1 : 0,
			
			cliente_codigo_postal: form.cliente_codigo_postal || '',
			cliente_rfc: form.cliente_rfc || '',
			cliente_nombre_razon_social: form.cliente_nombre_razon_social || '',
			id_proyecto: 3
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Guardar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_empresa: form.id_empresa || '',
			id_cat_tipo_persona: form.id_cat_tipo_persona || '',
			nombre_razon_social: form.nombre_razon_social || '',
			rfc: form.rfc || '',
			sucursal: form.sucursal || '',
			correo_electronico: form.correo_electronico || '',
			telefono: form.telefono || '',
			id_cat_pais: form.id_cat_pais || '',
			codigo_postal: form.codigo_postal || '',
			password: form.password || '',
			pagina_web: form.pagina_web || '',
			serie: form.serie || '',
			folio_inicial: form.folio_inicial || '',
			
			logo_base64Tipo: form.logo_base64Tipo || '',
			logo_base64: form.logo_base64 || '',
			logo_archivo: form.logo_archivo || '',
			logo_formato: form.logo_formato || '',
			
			cer_base64Tipo: form.cer_base64Tipo || '',
			cer_base64: form.cer_base64 || '',
			cer_archivo: form.cer_archivo || '',
			cer_formato: form.cer_formato || '',
			
			key_base64Tipo: form.key_base64Tipo || '',
			key_base64: form.key_base64 || '',
			key_archivo: form.key_archivo || '',
			key_formato: form.key_formato || '',
			
			terminos_condiciones: form.terminos_condiciones ? 1 : 0,
			activo: form.activo ? 1 : 0,
			id_proyecto: 3
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Modificar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cliente) => {
		let params = {
			id_cliente: id_cliente
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Eliminar', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ConsultaSat: (form) => {
		let params = {
			uuid: form.uuid,
			rfc_emisor: form.rfc_emisor,
			rfc_receptor: form.rfc_receptor,
			total: form.total,
			produccion: false,
			id_proyecto: 3
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Consulta_SAT', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ConsultaCFDI: (form) => {
		let params = {
			uuid: form.uuid,
			produccion: false,
			id_proyecto: 3
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Consulta_CFDI', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	EnviarCFDI: (form) => {
		let params = {
			uuid: form.uuid,
			enviar_a: form.enviar_a || '',
			enviar_cc: form.enviar_cc || '',
			produccion: false,
			id_proyecto: 3
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Enviar_CFDI', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ConsultaCFDIProyecto: (form) => {
		let params = {
			id_venta: form.id_venta,
			id_proyecto: 3
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Contulta_Venta_Proyecto', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ConsultaPagoCFDIProyecto: (form) => {
		let params = {
			id_venta: form.id_venta,
			id_venta_pago: form.id_venta_pago,
			id_proyecto: 3
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Contulta_Pago_Venta_Proyecto', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	CancelacionCFDI: (form) => {
		let params = {
			id_venta: form.id_venta,
			uuid: form.uuid,
			observaciones: form.observaciones,
			id_cat_cancelacion_cfdi: form.id_cat_cancelacion_cfdi,
			folio_fiscal_sustituye: form.folio_fiscal_sustituye,
			sustituye: form.sustituye,
			id_empresa: form.id_empresa || '',
			id_empresa_sucursal: form.id_empresa_sucursal || '',
			id_proyecto: 3
		};
		
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Cancelacion_CFDI_Venta_Proyecto', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	PagoCFDI: (form) => {
		let params = {
			id_facturacion_cfdi: form.id_facturacion_cfdi,
			id_venta: form.id_venta,
			id_venta_pago: form.id_venta_pago,
			fecha_pago: moment(new Date(form.fecha_pago)).format('DD/MM/YYYY hh:mm:ss a').toString(),
			id_cat_forma_pago: form.id_cat_forma_pago,
			monto: form.monto,
			rfc_emisor_cuenta_ord: form.rfc_emisor_cuenta_ord,
			banco: form.banco,
			rfc_emisor_cuenta_ben: form.rfc_emisor_cuenta_ben,
			cuenta_beneficiario: form.cuenta_beneficiario,
			numero_parcialidad: form.numero_parcialidad,
			importe_saldo_anterior: form.importe_saldo_anterior,
			importe_pagado: form.importe_pagado,
			id_cat_objeto_impuesto: form.id_cat_objeto_impuesto,
			importe_saldo_insoluto: form.importe_saldo_insoluto,
			fecha: form.fecha,
			enviar_a: form.enviar_a,
			enviar_cc: form.enviar_cc,
			enviar_cco: form.enviar_cco,
			serie: form.serie || '',
			folio: form.folio || '',
			id_cat_regimen_fiscal: form.id_cat_regimen_fiscal || '',
			lugar_expedicion: form.lugar_expedicion || '',
			cliente_regimen_fiscal: form.cliente_regimen_fiscal || '',
			cliente_numero_registro_if: form.cliente_numero_registro_if || '',
			cliente_codigo_postal: form.cliente_codigo_postal || '',
			cliente_rfc: form.cliente_rfc || '',
			cliente_nombre_razon_social: form.cliente_nombre_razon_social || '',
			id_proyecto: 3
		};
		console.log("::::::", params);
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Timbrado_Pago_CFDI', params, {facturacion: true}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
