import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import Grid from "@material-ui/core/Grid";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Typography from "@material-ui/core/Typography";

import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import {EditOutlined, DeleteOutlined} from "@material-ui/icons";

import vista_vacia_forma_de_pago from '../../../../assets/img/icons/vista-vacia-forma-de-pago.svg';
import item_forma_de_pago_azul from '../../../../assets/img/icons/item-forma-de-pago-azul.svg';
import item_forma_de_pago_gris from '../../../../assets/img/icons/item-forma-de-pago-gris.svg';
import tarjeta_de_debito from '../../../../assets/img/icons/tarjeta-de-debito.svg';
import tarjeta_de_credito from '../../../../assets/img/icons/tarjeta-de-credito.svg';
import transferencia_bancaria from '../../../../assets/img/icons/transferencia-bancaria.svg';
import pago_en_efectivo from '../../../../assets/img/icons/pago-en-efectivo.svg';
import mercado_pago from '../../../../assets/img/icons/mercado_pago.svg';
import co_di from '../../../../assets/img/icons/co_di.svg';
import cheque from '../../../../assets/img/icons/cheque.svg';
import ver_mas from '../../../../assets/img/icons/ver-mas.svg';
import ver_menos from '../../../../assets/img/icons/ver-menos.svg';
import icon_ticket from '../../../../assets/img/icons/icon-ticket.svg';
import circulo_a from '../../../../assets/img/icons/circulo-aprobado.svg';
import venta_pagado from '../../../../assets/img/icons/venta-pagado.svg';

import ItemFormaPago from './ItemFormaPago';
import FormPagoEfectivo from './FormPagoEfectivo';
import FormTransferenciaBancaria from "./FormTransferenciaBancaria";
import FormTarjetaCredito from "./FormTarjetaCredito";
import FormTarjetaDebito from "./FormTarjetaDebito";
import FormMercadoPago from "./FormMercadoPago";
import FormCoDi from "./FormCoDi";
import PropTypes from "prop-types";
import {HttpRequest} from "../../../../settings/HttpRequest/HttpRequest";
import Checkbox from "@material-ui/core/Checkbox";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {KeyboardDatePicker} from '@material-ui/pickers';
import FormCheque from "./FormCheque";
import ModalFormaPagoEdit from "./ModalFormaPagoEdit";
import {VentasService} from "../../../../services/VentasService/VentasService";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ProductoService} from "../../../../services/ProductoService/ProductoService";


class ModalPagarOrdenDeVenta extends Component {
	
	Usr = {};
	state = {};
	
	constructor(props) {
		super(props);
		this.formHTML = this.formHTML.bind(this);
		this.state = {
			ver: false,
			ver_producto_anticipi_linea_produccion: false
		};
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	formHTML = () => {
		const bg_vista_vacia_forma_de_pago = {
			backgroundImage: `url(${vista_vacia_forma_de_pago})`
		};
		let HTML = "";
		switch (this.props.form.id_cat_forma_pago) {
			case 1:
				HTML = (
					<Fragment>
						<FormPagoEfectivo form={this.props.form} Pagar={this.props.Pagar}
						                  handleChangePago={this.props.handleChangePago}/>
					</Fragment>
				);
				break;
			case 3:
				HTML = (
					<Fragment>
						<FormTransferenciaBancaria form={this.props.form} Pagar={this.props.Pagar} cat_banco={this.props.cat_banco}
						                           handleChangePago={this.props.handleChangePago}/>
					</Fragment>
				);
				break;
			case 4:
				HTML = (
					<Fragment>
						<FormTarjetaCredito form={this.props.form} Pagar={this.props.Pagar}
						                    handleChangePago={this.props.handleChangePago}/>
					</Fragment>
				);
				break;
			case 18:
				HTML = (
					<Fragment>
						<FormTarjetaDebito form={this.props.form} Pagar={this.props.Pagar}
						                   handleChangePago={this.props.handleChangePago}/>
					</Fragment>
				);
				break;
			case 22:
				HTML = (
					<Fragment>
						<FormMercadoPago form={this.props.form} Pagar={this.props.Pagar}
						                 handleChangePago={this.props.handleChangePago}/>
					</Fragment>
				);
				break;
			case 21:
				HTML = (
					<Fragment>
						<FormCoDi form={this.props.form} Pagar={this.props.Pagar}
						          handleChangePago={this.props.handleChangePago}/>
					</Fragment>
				);
				break;
			case 2:
				HTML = (
					<Fragment>
						<FormCheque form={this.props.form} Pagar={this.props.Pagar} cat_banco={this.props.cat_banco}
						            handleChangePago={this.props.handleChangePago}/>
					</Fragment>
				);
				break;
			default:
				HTML = (
					<div className={'w-100 padding-10 opacity-lista-vacia'}
					     align={'center'}>
						<div
							className={'bg-img-cover-x-center-y-center h-100-px w-100-px margin-10'}
							style={bg_vista_vacia_forma_de_pago}/>
						<Typography className={'px-20 padding-10'}>
							Selecciona el<br/>método de pago
						</Typography>
					</div>
				);
		}
		return HTML;
	};
	
	faltaparaanticipo = () => {
		let anticipo_minimo = ((this.props.pagos.ws_total_venta - this.props.pagos.ws_descuento_venta) / 2);
		if (Number(this.props.pagos.ws_total_pagado) < Number(anticipo_minimo)) {
			return Number(anticipo_minimo) - Number(this.props.pagos.ws_total_pagado);
		} else {
			return 0;
		}
	};
	
	anticipopagado_ventapagado = () => {
		let anticipo_minimo = ((this.props.pagos.ws_total_venta - this.props.pagos.ws_descuento_venta) / 2);
		return Number(this.props.pagos.ws_total_pagado) >= Number(anticipo_minimo)
	};
	
	permiso = () => {
		return this.props.pagos.ws_por_pagar > 0;
	};
	
	ticket = (item) => {
		HttpRequest.export('txid', ['ticket'], {
			id_venta_pago: item.id_venta_pago
		}, true, 450, 680)
	};
	
	anticipo_seleccionado = () => {
		let total = 0;
		for (let i = 0; i < this.props.productos_anticipo.length; i++) {
			let item = this.props.productos_anticipo[i];
			if (item.cubrir_anticipo_checked) {
				total += ((item.venta_precio - item.venta_descuento) / 2) - (item.anticipo || 0);
			}
		}
		return total;
	};
	
	total_seleccionado = () => {
		let total = 0;
		for (let i = 0; i < this.props.productos_anticipo.length; i++) {
			let item = this.props.productos_anticipo[i];
			if (item.cubrir_total_checked) {
				total += ((item.venta_precio - item.venta_descuento) / 1) - (item.anticipo || 0);
			}
		}
		return total;
	};
	
	eliminarPago = (item) => {
		console.log(this.state);
		let msg = `¿Deseas eliminar el pago con monto ${'$'+FieldsJs.Currency(item.monto)}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				VentasService.EliminarPago(item).then((response) => {
					this.props.closeModal(1, 1);
					this.props.RefreshList();
					this.props.showSnackBars('success', response.mensaje);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
		
	};
	
	render() {
		
		const bg_item_forma_de_pago_azul = {
			backgroundImage: `url(${item_forma_de_pago_azul})`
		};
		
		const bg_item_forma_de_pago_gris = {
			backgroundImage: `url(${item_forma_de_pago_gris})`
		};
		
		const bg_tarjeta_de_debito = {
			backgroundImage: `url(${tarjeta_de_debito})`
		};
		
		const bg_tarjeta_de_credito = {
			backgroundImage: `url(${tarjeta_de_credito})`
		};
		
		const bg_transferencia_bancaria = {
			backgroundImage: `url(${transferencia_bancaria})`
		};
		
		const bg_pago_en_efectivo = {
			backgroundImage: `url(${pago_en_efectivo})`
		};
		
		const bg_mercado_pago = {
			backgroundImage: `url(${mercado_pago})`
		};
		
		const bg_co_di = {
			backgroundImage: `url(${co_di})`
		};
		
		const bg_cheque = {
			backgroundImage: `url(${cheque})`
		};
		
		const bg_icon_ticket = {
			backgroundImage: `url(${icon_ticket})`
		};
		
		const bg_circulo_a = {
			backgroundImage: `url(${circulo_a})`,
			display: "inline-block"
		};
		
		const bg_venta_pagado = {
			backgroundImage: `url(${venta_pagado})`
		};
		
		const formHTML = this.formHTML();
		
		const bg_ver = {
			backgroundImage: `url(${(this.state.ver ? ver_menos : ver_mas)})`
		};
		
		const faltaparaanticipo = this.faltaparaanticipo();
		
		const anticipopagado_ventapagado = this.anticipopagado_ventapagado();
		
		const permiso = this.permiso();
		
		const anticipo_seleccionado = this.anticipo_seleccionado();
		
		const total_seleccionado = this.total_seleccionado();
		
		const total_general_seleccionado = anticipo_seleccionado + total_seleccionado;
		
		return (
			<div>
				
				<Dialog open={this.props.modal.open} onClose={() => this.props.closeModal(1)} disableEscapeKeyDown
				        disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				        
				>
					
					<DialogTitle>
						<Grid container spacing={0}>
							<Grid item xs={9}>
								<Typography className={'px-20'}>
									{!permiso ? (
										<Fragment>
											Resumen de pagos
										</Fragment>
									) : (
										<Fragment>
											{this.props.modal.title}
										</Fragment>
									)}
								</Typography>
								{permiso ? (
									<Typography className={'px-14 text-lightslategray'}>
										{this.props.modal.subtitle}
									</Typography>
								) : ""}
								{this.props.pagos.ws_numero_pedido ? (
									<Typography className={'px-20 text-danger'}>
										{this.props.pagos.ws_numero_pedido}
									</Typography>
								) : ""}
							</Grid>
							<Grid item xs={3} align={"right"}>
								<Typography className={'px-25 text-right'}>
									$ {FieldsJs.Currency(this.props.form.total_venta)}
								</Typography>
								{this.props.form.descuento_venta > 0 ? (
									<Typography className={'px-12 text-right text-blue'}>
										{/*Descuento: $ {FieldsJs.Currency(this.props.form.descuento_venta)}*/}
										Descuento: $ {FieldsJs.Currency(this.props.form.descuento_venta * 1.16)}
									</Typography>
								) : ''}
								{(this.props.form.total_venta > 0 && permiso) ? (
									<Typography className={'px-18 text-right'}>
										Anticipo mínimo:
										$ {FieldsJs.Currency(this.props.form.total_venta / 2)}
										{/*$ {FieldsJs.Currency(((this.props.form.total_venta - this.props.form.descuento_venta) / 2))}*/}
									</Typography>
								) : ''}
								<small
									className={'w-auto padding-2 padding-5-L padding-5-R b-r-2 bg-lightslategray px-10 cursor-pointer text-white'}
									onClick={() => {
										let ver_producto_anticipi_linea_produccion = !this.state.ver_producto_anticipi_linea_produccion;
										this.setState({ver_producto_anticipi_linea_produccion: ver_producto_anticipi_linea_produccion});
										this.props.toggle_linea_produccion(ver_producto_anticipi_linea_produccion);
									}}>
									{permiso ? "Anticipo lineas de producción" : "Lineas de producción"}
								</small>
							</Grid>
						</Grid>
					</DialogTitle>
					
					<DialogContent>
						
						
						<Grid container spacing={2}>
							
							{!permiso ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={"center"}>
										
										<div className={'bg-img-contain-x-center-y-center h-100-px w-100-px'}
										     style={bg_venta_pagado}/>
										
										<Typography className={'px-18 text-center text-gray'}>
											Orden de venta pagada
										</Typography>
									
									</Grid>
								</Fragment>
							) : ""}
							
							<Fragment>
								
								{(this.state.ver_producto_anticipi_linea_produccion) ? (
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										
										<Grid container spacing={2}>
											<Grid item xs={12} sm={permiso ? 9 : 12} md={permiso ? 9 : 12}
											      lg={permiso ? 9 : 12} xl={permiso ? 9 : 12}>
												
												<div className={"w-auto h-auto bg-lightgray padding-10"}>
													<table className={'w-100'}>
														<thead>
														<tr>
															<th align={'center'}
															    className={'text-center px-10 padding-10-T padding-5-B margin-5-B border-desglose-b ff-arial'}>
																Producto
															</th>
															<th align={'center'}
															    className={'text-center px-10 padding-10-T padding-5-B margin-5-B border-desglose-b ff-arial'}>
																Anticipo mínimo
															</th>
															<th align={'center'}
															    className={'text-center px-10 padding-10-T padding-5-B margin-5-B border-desglose-b ff-arial'}>
																Anticipo pendiente
															</th>
															<th align={'center'}
															    className={'text-center px-10 padding-10-T padding-5-B margin-5-B border-desglose-b ff-arial'}>
																Por pagar
															</th>
															<th align={'center'}
															    className={'text-center px-10 padding-10-T padding-5-B margin-5-B border-desglose-b ff-arial'}>
																Cubrir anticipo
															</th>
															<th align={'center'}
															    className={'text-center px-10 padding-10-T padding-5-B margin-5-B border-desglose-b ff-arial'}>
																Cubrir total
															</th>
															<th align={'center'}
															    className={'text-center px-10 padding-10-T padding-5-B margin-5-B border-desglose-b ff-arial'}>
																Total abonado
															</th>
														</tr>
														</thead>
														<tbody>
														{this.props.productos_anticipo.map((item, key) => (
															<tr key={key}>
																<td align={'center'}
																    className={'text-center ff-arial'}>
																	{item.venta_producto}
																</td>
																<td align={'center'}
																    className={'text-center ff-arial'}>
																	{(item.cubrir_anticipo || 0) === 1 ? (
																		<div style={bg_circulo_a}
																		     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}/>
																	) : (
																		<div>
																			${FieldsJs.Currency((item.venta_precio - item.venta_descuento) / 2)}
																		</div>
																	)}
																</td>
																<td align={'center'}
																    className={'text-center ff-arial'}>
																	{(item.cubrir_anticipo || 0) === 1 ? (
																		<div style={bg_circulo_a}
																		     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}/>
																	) : (
																		<div>
																			${FieldsJs.Currency(((item.venta_precio - item.venta_descuento) / 2) - (item.anticipo || 0))}
																		</div>
																	)}
																</td>
																<td align={'center'}
																    className={'text-center ff-arial'}>
																	{(item.cubrir_total || 0) === 1 ? (
																		<div style={bg_circulo_a}
																		     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}/>
																	) : (
																		<div>
																			${FieldsJs.Currency((item.venta_precio - item.venta_descuento) - (item.anticipo || 0))}
																		</div>
																	)}
																</td>
																<td align={'center'}
																    className={'text-center ff-arial'}>
																	<div>
																		{(item.cubrir_anticipo || 0) === 1 ? (
																			<div style={{
																				width: "42px",
																				display: "inline-block"
																			}} align={'center'}>
																				<div style={bg_circulo_a}
																				     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}/>
																			</div>
																		) : (
																			<Checkbox fontSize="small"
																			          type="checkbox"
																			          color="primary"
																			          name={'cubrir_anticipo_checked__' + key}
																			          onChange={(e) => {
																				          this.props.handleChange(e, 'productos_anticipo', 'cubrir_anticipo_checked');
																				          if (!!item.cubrir_total_checked) {
																					          let es = FieldsJs.Copy(e);
																					          es.target.checked = !es.target.checked;
																					          this.props.handleChange(es, 'productos_anticipo', 'cubrir_total_checked');
																				          }
																			          }}
																			          checked={!!item.cubrir_anticipo_checked}
																			/>
																		)}
																	</div>
																</td>
																<td align={'center'}
																    className={'text-center ff-arial'}>
																	<div>
																		{(item.cubrir_total || 0) === 1 ? (
																			<div style={{
																				width: "42px",
																				display: "inline-block"
																			}} align={'center'}>
																				<div style={bg_circulo_a}
																				     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}/>
																			</div>
																		) : (
																			<Checkbox fontSize="small"
																			          type="checkbox"
																			          color="primary"
																			          name={'cubrir_total_checked__' + key}
																			          onChange={(e) => {
																				          this.props.handleChange(e, 'productos_anticipo', 'cubrir_total_checked');
																				          if ((item.cubrir_total || 0) === 0) {
																					          if (!!item.cubrir_anticipo_checked) {
																						          let es = FieldsJs.Copy(e);
																						          es.target.checked = !es.target.checked;
																						          this.props.handleChange(es, 'productos_anticipo', 'cubrir_anticipo_checked');
																					          }
																				          }
																			          }}
																			          checked={!!item.cubrir_total_checked}
																			/>
																		)}
																	</div>
																</td>
																<td align={'center'}
																    className={'text-center ff-arial'}>
																	${FieldsJs.Currency(item.anticipo || 0)}
																</td>
															</tr>
														))}
														</tbody>
													</table>
												</div>
											
											</Grid>
											
											{permiso ? (
												<Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
													
													<div
														className={"w-100-20-px h-100-20-px bg-lightgray padding-10 vertical-inline"}>
														<div className={'v-center w-100'}>
															<Typography className={'px-14 text-center'}>
																Anticipo seleccionado
															</Typography>
															<Typography className={'px-20 text-center'}>
																$ {FieldsJs.Currency((anticipo_seleccionado > 0.009 ? anticipo_seleccionado : 0))}
															</Typography>
															<Typography className={'px-14 text-center'}>
																Total seleccionado
															</Typography>
															<Typography className={'px-20 text-center'}>
																$ {FieldsJs.Currency((total_seleccionado > 0.009 ? total_seleccionado : 0))}
															</Typography>
															<Typography className={'px-14 text-center'}>
																- - - - - - - - - - - - - -
															</Typography>
															<Typography className={'px-20 text-center'}>
																$ {FieldsJs.Currency((total_general_seleccionado > 0.009 ? total_general_seleccionado : 0))}
															</Typography>
														</div>
													</div>
												
												</Grid>
											) : ""}
										</Grid>
									
									</Grid>
								) : ''}
							
							</Fragment>
							
							{permiso ? (
								<Fragment>
									
									<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
										<Grid container spacing={2}>
											
											<Grid item xs={12}>
												<div
													onClick={() => this.props.handleChangePagoIdCatFormaPago(1, "id_cat_forma_pago")}>
													<ItemFormaPago selected={this.props.form.id_cat_forma_pago === 1}
													               bg_item={this.props.form.id_cat_forma_pago === 1 ? bg_item_forma_de_pago_azul : bg_item_forma_de_pago_gris}
													               bg_icon={bg_pago_en_efectivo}
													               title={"Pago en efectivo"}
													/>
												</div>
											</Grid>
											
											<Grid item xs={12}>
												<div
													onClick={() => this.props.handleChangePagoIdCatFormaPago(3, "id_cat_forma_pago")}>
													<ItemFormaPago selected={this.props.form.id_cat_forma_pago === 3}
													               bg_item={this.props.form.id_cat_forma_pago === 3 ? bg_item_forma_de_pago_azul : bg_item_forma_de_pago_gris}
													               bg_icon={bg_transferencia_bancaria}
													               title={"Transferencia / Depósito bancario"}
													/>
												</div>
											</Grid>
											
											<Grid item xs={12}>
												<div
													onClick={() => this.props.handleChangePagoIdCatFormaPago(4, "id_cat_forma_pago")}>
													<ItemFormaPago selected={this.props.form.id_cat_forma_pago === 4}
													               bg_item={this.props.form.id_cat_forma_pago === 4 ? bg_item_forma_de_pago_azul : bg_item_forma_de_pago_gris}
													               bg_icon={bg_tarjeta_de_credito}
													               title={"Tarjeta de crédito"}
													/>
												</div>
											</Grid>
											
											<Grid item xs={12}>
												<div
													onClick={() => this.props.handleChangePagoIdCatFormaPago(18, "id_cat_forma_pago")}>
													<ItemFormaPago selected={this.props.form.id_cat_forma_pago === 18}
													               bg_item={this.props.form.id_cat_forma_pago === 18 ? bg_item_forma_de_pago_azul : bg_item_forma_de_pago_gris}
													               bg_icon={bg_tarjeta_de_debito}
													               title={"Tarjeta de débito"}
													/>
												</div>
											</Grid>
											
											{/*<Grid item xs={12}>
												<div
													onClick={() => this.props.handleChangePagoIdCatFormaPago(22, "id_cat_forma_pago")}>
													<ItemFormaPago selected={this.props.form.id_cat_forma_pago === 22}
													               bg_item={this.props.form.id_cat_forma_pago === 22 ? bg_item_forma_de_pago_azul : bg_item_forma_de_pago_gris}
													               bg_icon={bg_mercado_pago}
													               title={"Mercado pago"}
													/>
												</div>
											</Grid>
											
											<Grid item xs={12}>
												<div
													onClick={() => this.props.handleChangePagoIdCatFormaPago(21, "id_cat_forma_pago")}>
													<ItemFormaPago selected={this.props.form.id_cat_forma_pago === 21}
													               bg_item={this.props.form.id_cat_forma_pago === 21 ? bg_item_forma_de_pago_azul : bg_item_forma_de_pago_gris}
													               bg_icon={bg_co_di}
													               title={"CoDi"}
													/>
												</div>
											</Grid>*/}
											
											<Grid item xs={12}>
												<div
													onClick={() => this.props.handleChangePagoIdCatFormaPago(2, "id_cat_forma_pago")}>
													<ItemFormaPago selected={this.props.form.id_cat_forma_pago === 2}
													               bg_item={this.props.form.id_cat_forma_pago === 2 ? bg_item_forma_de_pago_azul : bg_item_forma_de_pago_gris}
													               bg_icon={bg_cheque}
													               title={"Cheque"}
													/>
												</div>
											</Grid>
										
										</Grid>
									</Grid>
									
									<Grid item xs={12} sm={8} md={8} lg={8} xl={8} className={'padding-20-L'}>
										<div className={'padding-20-L'}>
											<Fragment>
												<div className={'borde-punteado-gray-1 padding-15 margin-10-B'}>
													<Grid container spacing={1}>
														
														<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
															
															<Grid container spacing={2} alignItems={"flex-and"}>
																<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
																	<Typography className={'px-14 text-left'} style={{color: '#415f6c'}}>
																		Si la fecha de pago es diferente al día de hoy, por favor seleccione la fecha en la que se realizó la operación.
																	</Typography>
																</Grid>
																<Grid item xs={12} sm={12} md={4} lg={4} xl={4} align={'center'}>
																	<KeyboardDatePicker
																		format={'dd/MM/yyyy'}
																		fullWidth
																		clearable
																		inputProps={{readOnly: true}}
																		KeyboardButtonProps={{
																			'aria-label': 'change date',
																		}}
																		label="Fecha de pago"
																		name="fecha_pago"
																		value={this.props.form.fecha_pago}
																		onChange={(e) => {
																			this.props.handleChangePago(e, null, null, e, 'fecha_pago');
																		}}
																		clearLabel={'Limpiar'}
																		okLabel={'Aceptar'}
																		cancelLabel={'Cancelar'}
																	/>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</div>
											</Fragment>
											
											{formHTML}
										</div>
									
									</Grid>
								
								</Fragment>
							) : ""}
							
							{this.props.pagos.ws_lista_pagos.length > 0 ? (
								<Fragment>
									{faltaparaanticipo > 0 ? (
										<Grid item xs={6} align={"center"}>
											
											<Typography className={'px-18 text-center'}>
												Pendiente de anticipo mínimo: <span
												className={'px-25 ff-arial'}>${FieldsJs.Currency(faltaparaanticipo)}</span>
											</Typography>
										
										</Grid>
									) : ""}
									{this.props.pagos.ws_por_pagar > 0 ? (
										<Grid item xs={(faltaparaanticipo > 0 ? 6 : 12)} align={"center"}>
											
											<Typography className={'px-18 text-center'}>
												Saldo total: <label className={'px-28 ff-arial'}>${FieldsJs.Currency(this.props.pagos.ws_por_pagar)}</label>
											</Typography>
										
										</Grid>
									) : ""}
								</Fragment>
							) : ""}
							
							{this.state.ver && this.props.pagos.ws_lista_pagos.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									
									<div className={'padding-10 bg-lightgray'}>
										<table className={'w-100'}>
											<thead>
											<tr>
												<th className={'text-center px-14 padding-10-T padding-5-B margin-5-B border-desglose-b'}>
													Acciones
												</th>
												<th className={'text-center px-14 padding-10-T padding-5-B margin-5-B border-desglose-b'}>
													Ticket
												</th>
												<th className={'text-left px-14 padding-10-T padding-5-B margin-5-B border-desglose-b'}>
													Banco
												</th>
												<th className={'text-left px-14 padding-10-T padding-5-B margin-5-B border-desglose-b'}>
													Forma de pago
												</th>
												<th className={'text-left px-14 padding-10-T padding-5-B margin-5-B border-desglose-b'}>
													Fecha de pago
												</th>
												<th className={'text-right px-14 padding-10-T padding-5-B margin-5-B border-desglose-b'}>
													Monto
												</th>
											</tr>
											</thead>
											<tbody>
											{this.props.pagos.ws_lista_pagos.map((item, key) => (
												<tr key={key}>
													<td align={'center'}>
														<div className={'vertical-inline'}>
															<div className={'v-center'}>
																<ModalFormaPagoEdit
																	componente={
																		(
																			<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
																				<IconButton aria-label="Editar">
																					<EditOutlined/>
																				</IconButton>
																			</Tooltip>
																		)
																	}
																	closeModalPagos={() => this.props.closeModal(1, 1)}
																	item={item}
																	showSnackBars={this.props.showSnackBars}
																	RefreshList={this.props.RefreshList}
																/>
															</div>
															<div className={'v-center'}>
																{ Number(this.Usr.id_cat_tipo_usuario) === 1 || Number(this.Usr.id_cat_tipo_usuario) === 2 ? (
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar" onClick={()=>this.eliminarPago(item)}>
																		<IconButton aria-label="Eliminar">
																			<DeleteOutlined/>
																		</IconButton>
																	</Tooltip>
																) : null}
															</div>
														</div>
													</td>
													<td align={'center'}>
														<div
															className={'bg-img-contain-x-center-y-center h-25-px w-25-px cursor-pointer'}
															style={bg_icon_ticket}
															onClick={() => this.ticket(item)}/>
													</td>
													<td>
														<Typography className={'px-12 text-left'}>
															{item.banco ?? "-"}
														</Typography>
													</td>
													<td>
														<Typography className={'px-12 text-left'}>
															{item.forma_pago}
														</Typography>
													</td>
													<td>
														<Typography className={'px-12 text-left'}>
															{DateFormat.FormatText(item.fecha_alta, true)}
														</Typography>
													</td>
													<td>
														<Typography className={'px-12 text-right'}>
															${FieldsJs.Currency(item.monto)}
														</Typography>
													</td>
												</tr>
											))}
											<tr>
												<td className={'border-desglose-t padding-10-T'}/>
												<td className={'border-desglose-t padding-10-T'}/>
												<td className={'border-desglose-t padding-10-T'}/>
												<td className={'border-desglose-t padding-10-T'}/>
												<td className={'border-desglose-t padding-10-T'}>
													<Typography className={'px-12 text-right'}>
														TOTAL PAGADO:
													</Typography>
												</td>
												<td className={'border-desglose-t padding-10-T'}>
													<Typography className={'px-12 text-right'}>
														${FieldsJs.Currency(this.props.pagos.ws_total_pagado)}
													</Typography>
												</td>
											</tr>
											<tr>
												<td/>
												<td/>
												<td/>
												<td/>
												<td>
													<Typography className={'px-12 text-right'}>
														SALDO:
													</Typography>
												</td>
												<td>
													<Typography className={'px-12 text-right'}>
														${FieldsJs.Currency(this.props.pagos.ws_por_pagar)}
													</Typography>
												</td>
											</tr>
											<tr>
												<td/>
												<td/>
												<td/>
												<td/>
												<td>
													<Typography className={'px-12 text-right'}>
														TOTAL VENTA:
													</Typography>
												</td>
												<td>
													<Typography className={'px-12 text-right'}>
														${FieldsJs.Currency((this.props.pagos.ws_total_venta - this.props.pagos.ws_descuento_venta))}
													</Typography>
												</td>
											</tr>
											</tbody>
										</table>
									</div>
								
								</Grid>
							) : ''}
							
							{this.props.pagos.ws_lista_pagos.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={"center"}>
									<div className={'cursor-pointer'} onClick={() => {
										this.setState({ver: !this.state.ver})
									}}>
										{!this.state.ver ? <Typography className={'px-12 text-center text-gray'}>Ver
											Pagos</Typography> : ''}
										<div className={'bg-img-contain-x-center-y-center h-20-px w-40-px'}
										     style={bg_ver}/>
										{this.state.ver ? <Typography className={'px-12 text-center text-gray'}>Ocultar
											Pagos</Typography> : ''}
									</div>
								</Grid>
							) : ''}
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={0}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.props.closeModal(1, 1)} color="primary">
									Cerrar ventana
								</Button>
								{/*{!this.props.id_venta_pagar_exportar ? (
									<Button onClick={() => {
										let smg = "¿Estas seguro de salir de la configuración de la venta, se perderán los datos capturados?";
										PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, smg).then((r) => {
											if (r.button === 'Aceptar') {
												window.history.back();
											}
										});
									}} color="primary">Cancelar orden</Button>
								) : ''}*/}
							</Grid>
							<Grid item xs={6} align={'right'}>
								{anticipopagado_ventapagado && this.props.pagos.ws_lista_pagos.length > 0 ? (
									<Button onClick={() => this.props.VerExportarOrden()} color="primary">
										Ver o exportar orden
									</Button>
								) : ""}
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			
			</div>
		);
	}
}


ModalPagarOrdenDeVenta.propTypes = {
	handleChangePagoIdCatFormaPago: PropTypes.func.isRequired,
	handleChangePago: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	Pagar: PropTypes.func.isRequired,
	pagos: PropTypes.object.isRequired,
	VerExportarOrden: PropTypes.func.isRequired,
	productos_anticipo: PropTypes.array.isRequired,
	cat_banco: PropTypes.array.isRequired,
	toggle_linea_produccion: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	id_venta_pagar_exportar: PropTypes.number,
};


export default ModalPagarOrdenDeVenta;
