import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputAdornment from '@material-ui/core/InputAdornment';
import {KeyboardDatePicker} from '@material-ui/pickers';
import Button from "@material-ui/core/Button";
import {CadenaDomicilio, EnteroSolo, FieldsJs, FloatSolo, MinMax} from '../../../../settings/General/General';
import AutoCompleteCliente from './AutoCompleteCliente';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {DeleteOutlined, LocalParking, LocalShipping, Send, Description} from '@material-ui/icons';
import add_cliente_venta from '../../../../assets/img/icons/add-cliente-venta.svg';
import edit_cliente_venta from '../../../../assets/img/icons/edit-cliente-venta.svg';
import view_cliente_venta from '../../../../assets/img/icons/view-cliente-venta.svg';
import agregar_producto_requerimiento_adicional from '../../../../assets/img/icons/agregar-producto-atributo.svg';
import carnet_identificacion from '../../../../assets/img/icons/carnet-de-identidad.svg';
import Input from "@material-ui/core/Input";
import Divider from "@material-ui/core/Divider";
import ModalAtributoRroducto from "./ModalAtributoRroducto";
import BtnMasAdd from "../../../Include/MiniComponents/BtnMasAdd"
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import NativeSelect from "@material-ui/core/NativeSelect";
import ModalCliente from "../../Cliente/Includes/ModalCliente";
import IconButton from "@material-ui/core/IconButton";
import Zoom from "@material-ui/core/Zoom/index";
import Tooltip from "@material-ui/core/Tooltip/index";
import ModalProductos from "./ModalProductos";
import Fab from "@material-ui/core/Fab";
import ModalCambiarClienteCFDI from "../../Ventas/Includes/ModalCambiarClienteCFDI";

const styles = {
	root: {
		background: "transparent",
		color: "black"
	},
	input: {
		color: "black"
	},
	input_number: {
		color: "black",
		textAlign: "right",
		alignText: "right",
	}
};


class ContenidoOrdenVenta extends Component {
	
	getAtrubutoOpcionRequerimineto = (itemTR) => {
		let info = ((itemTR.cat_opcion_requerimiento || []).find(value => value.id_cat_opcion_requerimiento === (Number(itemTR.id_cat_opcion_requerimiento))) || {})
		return !!info.opcion_requerimiento ? `(${info.opcion_requerimiento})` : ``
	}
	
	render() {
		
		const btn_add = {
			backgroundImage: `url(${add_cliente_venta})`,
			opacity: 1
		};
		
		const btn_edit = {
			backgroundImage: `url(${edit_cliente_venta})`,
			opacity: this.props.form.form_seleccionado.id_cliente > 1 ? 1 : 0.5
		};
		
		const btn_view = {
			backgroundImage: `url(${view_cliente_venta})`,
			opacity: this.props.form.form_seleccionado.id_cliente > 1 ? 1 : 0.5
		};
		
		const btn_agregar_producto_requerimiento_adicional = {
			backgroundImage: `url(${agregar_producto_requerimiento_adicional})`,
			opacity: 1
		};
		
		const lista_vacia_datos_cliente = {
			backgroundImage: `url(${carnet_identificacion})`,
			opacity: 0.5
		};
		
		const desglose = this.props.updateDesglose();
		
		const {classes} = this.props;
		
		let disabledInputVenta = !!this.props.id_venta;
		
		let disabledInputVentaEditar = !!this.props.id_venta && !this.props.editar;
		
		let disabledInputVentaVer = !!this.props.id_venta && this.props.ver;
		
		let cliente_facturacion = this.props.form ? this.props.form ? this.props.form.cliente_facturacion ? Number(this.props.form.cliente_facturacion.id_cat_tipo_persona) === 2 ? this.props.form.cliente_facturacion.razon_social : this.props.form.cliente_facturacion.nombre_completo : "" : "" : "";
		
		return (
			<Fragment>
				
				<section className={'contentenido-nueva-venta w-auto padding-20-L padding-20-R'}>
					
					<Grid container spacing={3} direction="row" justify="flex-end" alignItems="flex-end" className={'h-100 margin-0-B margin-0-T'}>
						
						<Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={'h-100'}>
							
							<Grid container spacing={0} className={'h-100'}>
								
								{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-60-px'}>
									<div className={'row-flex h-52-px'}>
										<div className="w-100-150-px">
											<TextField type={'text'} fullWidth name="_numero_folio_orden_venta"
											           onChange={this.props.handleChange}
											           label="Número Folio Orden Base" autoComplete={'off'}
											           placeholder={"Número Folio Orden Base"}
											           value={this.props.form._numero_folio_orden_venta}/>
										</div>
										<div className="w-40-px vertical-inline">
											<div className={'w-100 v-end'} align={'right'}>
												<div onClick={() => this.props.OrdenDeVentaDatos(this.props.form._numero_folio_orden_venta, true)} className={'w-30-px h-30-px b-r-7 bg-danger bg-img-cover-x-center-y-center cursor-pointer'} style={btn_view}
												/>
											</div>
										</div>
										<div className="w-110-px vertical-inline">
											<div className={'w-100 v-end'} align={'right'}>
												<ModalPaquetes
													tipo={'add'}
													componente={
														<div className={'vertical-inline w-100 b-r-7 cursor-pointer bg-gray margin-5-L'}>
															<div className={'v-center'}>
																<img className={'padding-5-L padding-5-R'} src={pakageIcon} alt="add" width={28} height={28} style={{opacity: 0.7}}/>
															</div>
															<p className={'margin-0 px-13 v-center text-white'}>Paquetes</p>
														</div>
													}
													actualizarProductosPaquetes={this.props.actualizarProductosPaquetes}
													showSnackBars={this.props.showSnackBars}
												/>
											</div>
										</div>
									</div>
								</Grid>*/}
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-60-px'}>
									
									{disabledInputVentaEditar ? (
										<Fragment>
											{this.props.form.numero_pedido || this.props.form.numero_cotizacion ? (
												<Typography className={'text-left text-danger px-20'}>
													{this.props.form.numero_pedido || this.props.form.numero_cotizacion}
												</Typography>
											) : ''}
											<Typography className={'text-left px-14'}>
												{this.props.form.form_seleccionado.nombre} {this.props.form.form_seleccionado.apellido_paterno} {this.props.form.form_seleccionado.apellido_materno}
											</Typography>
										</Fragment>
									) : (
										<Fragment>
											<div className={'row-flex h-52-px'}>
												<div className="w-100-120-px">
													<AutoCompleteCliente
														handleChangeSingle={this.props.handleChangeSingle}
														suggestions={this.props.suggestions}
														value={this.props.form.id_cliente}
														name={'id_cliente'} id={'id_cliente'}
														label={'nombre_completo'}
													/>
												</div>
												
												<div className="w-40-px vertical-inline">
													<div className={'w-100 v-end'} align={'right'}>
														<ModalCliente
															tipo={'view'}
															item={this.props.form.form_seleccionado}
															RefreshList={() => this.props.RefreshListCliente(null)}
															showSnackBars={this.props.showSnackBars}
															componente={(
																<div
																	className={'w-30-px h-30-px b-r-7 bg-danger bg-img-cover-x-center-y-center cursor-pointer'}
																	style={btn_view}
																/>
															)}
														/>
													</div>
												</div>
												<div className="w-40-px vertical-inline">
													<div className={'w-100 v-end'} align={'right'}>
														<ModalCliente
															tipo={'edit'}
															item={this.props.form.form_seleccionado}
															RefreshList={() => this.props.RefreshListCliente(null)}
															showSnackBars={this.props.showSnackBars}
															componente={(
																<div
																	className={'w-30-px h-30-px b-r-7 bg-danger bg-img-cover-x-center-y-center cursor-pointer'}
																	style={btn_edit}
																/>
															)}
														/>
													</div>
												</div>
												<div className="w-40-px vertical-inline">
													<div className={'w-100 v-end'} align={'right'}>
														<ModalCliente
															tipo={'add'}
															item={{}}
															RefreshList={(id_cliente) => this.props.RefreshListCliente(id_cliente)}
															showSnackBars={this.props.showSnackBars}
															componente={(
																<div
																	className={'w-30-px h-30-px b-r-7 bg-danger bg-img-cover-x-center-y-center cursor-pointer'}
																	style={btn_add}
																/>
															)}
														/>
													</div>
												</div>
											</div>
										</Fragment>
									
									)}
								
								</Grid>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-60-px'}>
									
									<Card className={'borde-punteado-gray-1 padding-0 h-100'}>
										<CardContent className={'padding-5 h-100-12-px'}>
											
											{this.props.form.form_seleccionado.id_cliente !== 1 && (CadenaDomicilio(this.props.form.form_seleccionado) || this.props.form.form_seleccionado.rfc || this.props.form.form_seleccionado.correo_electronico || this.props.form.form_seleccionado.telefono || this.props.form.form_seleccionado.celular) ? (
												<Fragment>
													
													<div className={'w-100 h-100 vertical-inline'}>
														<div className={'w-100 v-center'} align={'center'}>
															
															{this.props.form.form_seleccionado.rfc ? (
																<Typography className={'px-14 text-left l-h-14'}>
																	R.F.C.: {this.props.form.form_seleccionado.rfc}
																</Typography>
															) : ''}
															
															{this.props.form.form_seleccionado.correo_electronico || this.props.form.form_seleccionado.telefono || this.props.form.form_seleccionado.celular ? (
																<Typography className={'px-14 text-left l-h-14'}>
																	{this.props.form.form_seleccionado.correo_electronico ? (
																		<span>{this.props.form.form_seleccionado.correo_electronico} </span>) : ''}
																	{this.props.form.form_seleccionado.telefono ? (
																		<span>|  Tel: {this.props.form.form_seleccionado.telefono} </span>) : ''}
																	{this.props.form.form_seleccionado.celular ? (
																		<span>|  Cel: {this.props.form.form_seleccionado.celular}</span>) : ''}
																</Typography>
															) : ''}
															
															{/*{CadenaDomicilio(this.props.form.form_seleccionado) ? (
																<Typography className={'px-12 text-left l-h-14'}>
																	{CadenaDomicilio(this.props.form.form_seleccionado)}
																</Typography>
															) : ''}*/}
														
														</div>
													</div>
												
												</Fragment>
											) : (
												<Fragment>
													<div className={'w-100 h-100 vertical-inline'}>
														<div className={'w-100 v-center'} align={'center'}>
															
															<div style={lista_vacia_datos_cliente}
															     className={'w-30-px h-30-px bg-img-cover-x-center-y-center'}/>
															
															<Typography color="textSecondary"
															            className={'px-14 text-center l-h-14'}>
																Venta para el publico en general
															</Typography>
														
														</div>
													</div>
												
												</Fragment>
											)}
										
										</CardContent>
									</Card>
								
								</Grid>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-40-px'}>
									
									<RadioGroup row aria-label="position"
									            name="factura_cotizacion_nota_sencilla_interno"
									            value={this.props.form.factura_cotizacion_nota_sencilla_interno}
									            onChange={(e) => {
										            this.props.handleChange(e);
										            this.props.updateDesglose();
									            }}>
										<FormControlLabel
											className={'px-12'}
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value="factura"
											label="Factura"
											disabled={disabledInputVentaEditar}
										/>
										<FormControlLabel
											className={'px-12'}
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value="cotizacion"
											label="Cotización"
											disabled={disabledInputVentaEditar}
										/>
										<FormControlLabel
											className={'px-12'}
											labelPlacement="end"
											control={<Radio color="primary"/>}
											value="nota_sencilla"
											label="Nota sencilla"
											disabled={disabledInputVentaEditar}
										/>
										{Number(this.props.id_cat_tipo_usuario) === 2 ? (
											<FormControlLabel
												className={'px-12'}
												labelPlacement="end"
												control={<Radio color="primary"/>}
												value="interno"
												label="Interno"
												disabled={disabledInputVenta}
											/>
										) : null}
									</RadioGroup>
								
								</Grid>
								
								{this.props.form.factura_cotizacion_nota_sencilla_interno === 'factura' ? (
									<Fragment>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-40-px'}>
											<ModalCambiarClienteCFDI
												tipo={'add'}
												componente={(
													<Fab variant="extended" size="small" color="primary" aria-label="Add">
														<Description className={'margin-5-R px-14'}/>
														Timbrar a otro receptor
													</Fab>
												)}
												item={null}
												RefreshList={this.props.RefreshListClienteFactura}
												showSnackBars={this.props.showSnackBars}
												history={this.props.history}
											/>
										</Grid>
										{cliente_facturacion ? (
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Card className={'padding-0'} style={{minHeight: 40}}>
													<CardContent className={'padding-4 padding-28-L'} style={{textAlign: 'left'}}>
														<div className={'vertical-inline'}>
															<div className={'v-center'}>
																<div className={'px-11'} style={{fontWeight: 'bold'}}>
																	Cliente Facturación:
																</div>
																<div className={'px-10'}>
																	{cliente_facturacion}
																</div>
															</div>
														</div>
													</CardContent>
												</Card>
											</Grid>
										) : null}
									</Fragment>
								) : null}
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-50-px'}>
									
									<Grid container spacing={2} direction="row">
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<Card className={'padding-0'} style={{height: 40}}>
												<CardContent className={'padding-4 padding-28-L'} style={{height: '100%', textAlign: 'left'}}>
													<div className={'vertical-inline'}>
														<div className={'v-center w-250-px'}>
															<FormControlLabel
																className={'h-30-px px-12 text-left'}
																control={
																	<Checkbox
																		fontSize="small"
																		type="checkbox"
																		color="primary"
																		name={'no_desglosar_iva'}
																		checked={!!this.props.form.no_desglosar_iva}
																		onChange={(e) => {
																			this.props.handleChange(e);
																			this.props.updateDesglose();
																			
																		}}
																		disabled={disabledInputVentaEditar}
																	/>
																}
																label={'No desglosar IVA.'}
																labelPlacement="end"
															/>
														</div>
														{/*{this.props.form.no_desglosar_iva ? (
																<div className={'v-center px-12'} style={{color: 'black', fontWeight: 'bold'}}>
																	Los importes se expresan en pesos mexicanos e incluyen IVA.
																</div>
															):null}*/}
													</div>
												</CardContent>
											</Card>
										
										</Grid>
										
										<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
											
											<Card className={'padding-0'} style={{height: 40}}>
												<CardContent className={'padding-4 padding-28-L'} style={{height: '100%', textAlign: 'left'}}>
													<div className={'vertical-inline'}>
														<div className={'v-center w-250-px'}>
															<FormControlLabel
																className={'h-30-px px-12 text-left'}
																control={
																	<Checkbox
																		fontSize="small"
																		type="checkbox"
																		color="primary"
																		name={'desglosar_iva'}
																		checked={!!this.props.form.desglosar_iva}
																		onChange={(e) => {
																			this.props.handleChange(e);
																			this.props.updateDesglose();
																		}}
																		disabled={disabledInputVentaEditar}
																	/>
																}
																label={'Desglosar IVA.'}
																labelPlacement="end"
															/>
														</div>
														{/*{this.props.form.no_desglosar_iva ? (
																<div className={'v-center px-12'} style={{color: 'black', fontWeight: 'bold'}}>
																	Los importes se expresan en pesos mexicanos e incluyen IVA.
																</div>
															):null}*/}
													</div>
												</CardContent>
											</Card>
										
										</Grid>
									
									</Grid>
								
								</Grid>
								{/*) : null}*/}
								
								
								{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-50-px'}>
									<Grid container spacing={2} direction="row" justify="center" alignItems="center">
										<Grid item xs={6} sm={5} md={5} lg={5} xl={5}>
											<Card className={'padding-0'} style={{height: 40}}>
												<CardContent className={'padding-4'} style={{height: '100%'}}>
													<FormControlLabel
														className={'h-30-px px-12 text-left'}
														control={
															<Checkbox
																fontSize="small"
																type="checkbox"
																color="primary"
																name={'por_hora'}
																checked={!!this.props.form.por_hora}
																onChange={(e) => this.props.handleChange(e)}
																disabled={disabledInputVenta}
															/>
														}
														label={'Definir x hrs.'}
														labelPlacement="end"
													/>
												</CardContent>
											</Card>
										
										</Grid>
										<Grid item xs={6} sm={7} md={7} lg={7} xl={7}>
											<Card className={'padding-0'} style={{height: 40}}>
												<CardContent className={'padding-0'} style={{height: '100%'}}>
													<AutoCompletado onChange={(e) => {
														this.props.apply_search(e);
													}}/>
												</CardContent>
											</Card>
										</Grid>
									</Grid>
								</Grid>*/}
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}
								      className={this.props.form.factura_cotizacion_nota_sencilla_interno === 'factura' && cliente_facturacion ? 'h-100-400-px padding-13-B' : this.props.form.factura_cotizacion_nota_sencilla_interno === 'factura' ? 'h-100-330-px padding-13-B' : 'h-100-260-px padding-13-B'}>
									
									<div className={'borde-punteado-gray-1 h-100-2-px'}>
										<Card className={'padding-0 h-100'}>
											<CardContent className={'padding-0 h-100'}>
												
												<div style={{
													overflowY: "auto",
													overflowX: "hidden",
													height: "calc(100% - 30px)",
													padding: "15px 15px 5px 15px"
												}}>
													<Fragment>
														<Grid container spacing={1} justify="flex-start" alignItems="flex-start">
															
															{FieldsJs.Field(this.props.selectEnvio()) ? (
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																	<Typography style={{color: '#b9996f'}} className={'px-12 text-left l-h-14'}>
																		{this.props.selectEnvio()}
																	</Typography>
																</Grid>
															) : null}
															
															{/*<Grid item xs={6} sm={6} md={3} lg={1} xl={1}>
																<ModalEnvio
																	tipo={'add'}
																	componente={
																		<Tooltip TransitionComponent={Zoom} placement={"top"} title="Envío">
																			<IconButton style={{padding: 3, backgroundColor: '#b9996f', color: 'white'}} aria-label="Envío" onClick={() => {
																			}}>
																				<LocalShipping/>
																			</IconButton>
																		</Tooltip>
																	}
																	form={this.props.form}
																	selectEnvio={this.props.selectEnvio}
																	RefreshList={this.props.RefreshListCliente}
																	showSnackBars={this.props.showSnackBars}
																	cat_tipo_mensajeria={this.props.cat_tipo_mensajeria}
																	cat_envio_estatus={this.props.cat_envio_estatus}
																/>
															</Grid>*/}
															
															<Grid item xs={6} sm={6} md={3} lg={1} xl={1}>
																<ModalProductos
																	tipo={'add'}
																	componente={
																		<Tooltip TransitionComponent={Zoom} placement={"top"} title="Productos">
																			<IconButton style={{padding: 3, backgroundColor: '#3f51b5', color: 'white'}} aria-label="Productos" onClick={() => {
																			}}>
																				<LocalParking/>
																			</IconButton>
																		</Tooltip>
																	}
																	selectProducto={this.props.selectProducto}
																	RefreshList={this.props.RefreshListCliente}
																	showSnackBars={this.props.showSnackBars}
																/>
															</Grid>
															
															<Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
																<TextField
																	multiline
																	fullWidth
																	type="text"
																	margin="none"
																	className={classes.root}
																	inputProps={{
																		className: classes.input,
																		placeholder: "Producto"
																	}}
																	
																	name={'_producto'}
																	onChange={this.props.handleChange}
																	value={this.props.form._producto}
																	disabled={disabledInputVentaEditar}
																/>
															</Grid>
															
															{/*<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
															</Grid>
															
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
																<TextField
																	multiline
																	fullWidth
																	type="text"
																	margin="none"
																	className={classes.root}
																	inputProps={{
																		className: classes.input,
																		placeholder: "Código del producto"
																	}}
																	name={'_cat_producto_codigo'}
																	onChange={(e) => {
																		this.props.handleChange(e);
																		this.props.ListCatProducto(4,e.target.value);
																	}}
																	value={this.props.form._cat_producto_codigo}
																	disabled={disabledInputVentaEditar}
																/>
															</Grid>
															
															<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
															</Grid>
															
															<Grid item xs={4} sm={4} md={6} lg={6} xl={6}>
																<NativeSelect
																	className={'w-100'}
																	name="_id_cat_producto_grupo"
																	value={this.props.form._id_cat_producto_grupo}
																	onChange={(e) => {
																		this.props.handleChange(e);
																		this.props.ListCatProducto(1,e.target.value);
																	}}
																	inputProps={{
																		'aria-label': '_id_cat_producto_grupo',
																	}}
																	disabled={disabledInputVentaEditar}
																>
																	<option value="">Selecciona un grupo
																	</option>
																	{this.props.cat_producto_grupo.map(option => (
																		<option
																			key={option.id_cat_producto_grupo}
																			value={option.id_cat_producto_grupo}
																			children={option.descripcion}
																		/>
																	))}
																</NativeSelect>
															</Grid>
															
															<Grid item xs={4} sm={4} md={6} lg={6} xl={6}>
																<NativeSelect
																	className={'w-100'}
																	name="_id_cat_producto_categoria"
																	value={this.props.form._id_cat_producto_categoria}
																	onChange={(e) => {
																		this.props.handleChange(e);
																		this.props.ListCatProducto(2,e.target.value);
																	}}
																	inputProps={{
																		'aria-label': '_id_cat_producto_categoria',
																	}}
																	disabled={disabledInputVentaEditar}
																>
																	<option value="">Selecciona un categoría
																	</option>
																	{this.props.cat_producto_categoria.map(option => (
																		<option
																			key={option.id_cat_producto_categoria}
																			value={option.id_cat_producto_categoria}
																			children={option.descripcion}
																		/>
																	))}
																</NativeSelect>
															</Grid>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<NativeSelect
																	className={'w-100'}
																	name="_id_cat_producto"
																	value={this.props.form._id_cat_producto}
																	onChange={(e) => {
																		this.props.handleChange(e);
																		this.props.ListCatProducto(3,e.target.value);
																	}}
																	inputProps={{
																		'aria-label': '_id_cat_producto',
																	}}
																	disabled={disabledInputVentaEditar}
																>
																	<option value="">Selecciona un producto
																	</option>
																	{this.props.cat_producto.map(option => (
																		<option
																			key={option.id_cat_producto}
																			value={option.id_cat_producto}
																			children={option.descripcion}
																		/>
																	))}
																</NativeSelect>
															</Grid>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<NativeSelect
																	className={'w-100'}
																	name="_id_cat_producto_detalle"
																	value={this.props.form._id_cat_producto_detalle}
																	onChange={(e) => {
																		this.props.handleChange(e);
																	}}
																	inputProps={{
																		'aria-label': '_id_cat_producto_detalle',
																	}}
																	disabled={disabledInputVentaEditar}
																>
																	<option value="">Selecciona un detalle
																	</option>
																	{this.props.cat_producto_detalle.map(option => (
																		<option
																			key={option.id_cat_producto_detalle}
																			value={option.id_cat_producto_detalle}
																			children={option.descripcion}
																		/>
																	))}
																</NativeSelect>
															</Grid>*/}
															
															<Grid item xs={4} sm={2} md={3} lg={3} xl={3}>
																<TextField
																	fullWidth
																	type="text"
																	margin="none"
																	className={classes.root}
																	inputProps={{
																		className: classes.input,
																		placeholder: "Cant."
																	}}
																	onKeyPress={EnteroSolo}
																	name={'_cantidad'}
																	onChange={this.props.handleChange}
																	value={this.props.form._cantidad}
																	disabled={disabledInputVentaEditar}
																/>
															</Grid>
															
															<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
																<TextField
																	fullWidth
																	type="text"
																	margin="none"
																	className={classes.root}
																	InputProps={{
																		inputProps: {
																			className: classes.input,
																			placeholder: "Precio U."
																		},
																		startAdornment: <InputAdornment
																			position="start">$</InputAdornment>
																	}}
																	onKeyPress={FloatSolo}
																	name={'_precio_unitario'}
																	onChange={this.props.handleChange}
																	value={this.props.form._precio_unitario}
																	disabled={disabledInputVentaEditar}
																/>
															</Grid>
															
															
															{/*<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
																<div className={'vertical-inline w-100 h-32-px'}>
																	<div className={'v-center'}>
																		<span style={{color: 'white', background: this.props.form.tipo_descuento ? 'rgba(100,100,100,0.4)' : '#3f51b5', borderRadius: '10px 0 0 10px', padding: '5px 8px 5px 10px'}}
																		      className={'px-14 cursor-pointer'} onClick={() => {
																			if (!disabledInputVentaEditar) {
																				this.props.tipoDescuento(false)
																			}
																		}}>%</span>
																	</div>
																	<div className={'v-center'}>
																		<span style={{color: 'white', background: !this.props.form.tipo_descuento ? 'rgba(100,100,100,0.4)' : '#3f51b5', borderRadius: '0 10px 10px 0', padding: '5px 10px 5px 8px'}}
																		      className={'px-14 cursor-pointer'} onClick={() => {
																			if (!disabledInputVentaEditar) {
																				this.props.tipoDescuento(true)
																			}
																		}}>$</span>
																	</div>
																</div>
															</Grid>
															
															<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
																<TextField
																	fullWidth
																	type="text"
																	margin="none"
																	className={classes.root}
																	InputProps={{
																		inputProps: {
																			className: classes.input,
																			placeholder: "Descuento"
																		},
																	}}
																	onKeyPress={FloatSolo}
																	name={'_porcentaje_descuento'}
																	onChange={this.props.handleChange}
																	value={this.props.form._porcentaje_descuento}
																	disabled={disabledInputVentaEditar}
																/>
															</Grid>*/}
															
															<Grid item xs={4} sm={4} md={8} lg={8} xl={8}>
																<NativeSelect
																	className={'w-100'}
																	name="_id_cat_grupo"
																	value={this.props.form._id_cat_grupo}
																	onChange={(e) => {
																		this.props.handleChange(e);
																		this.props.getCatTipoRequerimiento(e)
																	}}
																	inputProps={{
																		'aria-label': '_id_cat_grupo',
																	}}
																	disabled={disabledInputVentaEditar}
																>
																	<option value="">Selecciona un grupo
																	</option>
																	{this.props.cat_grupo.map(option => (
																		<option
																			key={option.id_cat_grupo}
																			value={option.id_cat_grupo}
																			children={option.grupo}
																		/>
																	))}
																</NativeSelect>
															</Grid>
															
															<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																<TextField
																	multiline
																	fullWidth
																	type="text"
																	margin="none"
																	className={classes.root}
																	inputProps={{
																		className: classes.input,
																		placeholder: "Descripción"
																	}}
																	
																	name={'_descripcion'}
																	onChange={this.props.handleChange}
																	value={this.props.form._descripcion}
																	disabled={disabledInputVentaEditar}
																/>
															</Grid>
															
															{this.props.cat_tipo_requerimiento.length > 0 ? (
																<Fragment>
																	{this.props.cat_tipo_requerimiento.map((item, key) => (
																		<Grid key={key} item xs={6} sm={6} md={6} lg={6} xl={6}>
																			
																			<Grid container spacing={0} justify="flex-end" alignItems="flex-end">
																				<Grid item className={(item.con_costo_adicional === 1 ? 'w-100-50-px' : 'w-100')}>
																					<FormGroup row>
																						<FormControlLabel
																							className={'h-30-px px-12 text-left'}
																							control={
																								<Checkbox
																									fontSize="small"
																									type="checkbox"
																									color="primary"
																									name={'tipo_requerimiento__' + key}
																									onChange={(e) => this.props.handleChange(e, 'cat_tipo_requerimiento', 'checked')}
																									checked={!!item.checked}
																									disabled={disabledInputVentaEditar}
																								/>
																							}
																							label={item.tipo_requerimiento}
																							labelPlacement="end"
																						/>
																					</FormGroup>
																				</Grid>
																				{item.con_costo_adicional === 1 ? (
																					<Grid item className={'w-50-px'}>
																						<Input placeholder="0.00"
																						       className={'px-14 text-right'}
																						       inputProps={{
																							       'aria-label': 'Costo adicional',
																						       }}
																						       name={'costo_adicional__' + key}
																						       onChange={(e) => this.props.handleChange(e, 'cat_tipo_requerimiento', 'costo_adicional')}
																						       disabled={disabledInputVentaEditar || !item.checked}
																						       value={item.costo_adicional}
																						/>
																					</Grid>
																				) : ""}
																				{FieldsJs.Array(item.cat_opcion_requerimiento) ? (
																					<Grid item xs={12}>
																						<RadioGroup
																							row
																							className={'h-25-px'}
																							aria-label="position"
																							name={'id_cat_opcion_requerimiento__' + key}
																							value={Number(item.id_cat_opcion_requerimiento)}
																							onChange={(e) => this.props.handleChange(e, 'cat_tipo_requerimiento', 'id_cat_opcion_requerimiento')}
																						>
																							{item.cat_opcion_requerimiento.map((corItem, corkey) => (
																								<FormControlLabel
																									className={'h-25-px px-10'}
																									key={corkey}
																									labelPlacement="end"
																									control={<Radio color="primary"/>}
																									value={corItem.id_cat_opcion_requerimiento}
																									label={corItem.opcion_requerimiento}
																									disabled={!!!item.checked || disabledInputVentaEditar}
																								/>
																							))}
																						</RadioGroup>
																					</Grid>
																				) : ""}
																			</Grid>
																		
																		</Grid>
																	))}
																</Fragment>
															) : (
																<Fragment>
																	<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																		<VistaVacia
																			padding={'5px'}
																			paddingText={'0px 20px 0px 20px'}
																			height={'55px'}
																			width={'55px'}
																			numero={0}
																			mensaje={'Selecciona un grupo para mostrar los requerimintos'}
																		/>
																	</Grid>
																</Fragment>
															)}
															
															{this.props.producto_requerimiento_adicional.map((item, key) => (
																<Grid key={key} item xs={12} sm={12} md={12} lg={12} xl={12}>
																	
																	<Grid container spacing={0}
																	      justify="center"
																	      alignItems="center">
																		
																		<Grid item className={'w-30-px'}
																		      align={'left'}>
																			<div className={'cursor-pointer'}
																			     style={{
																				     padding: '6px',
																				     background: 'red',
																				     height: '6px',
																				     width: '6px',
																				     lineHeight: '6px',
																				     color: 'white',
																				     borderRadius: '100%',
																				     fontSize: '12px',
																			     }}
																			     onClick={() => this.props.eliminar_producto_requerimiento_adicional(key)}>x
																			</div>
																		</Grid>
																		
																		<Grid item className={'w-100-80-px'}>
																			<Typography
																				className={'text-left px-14'}>
																				{item.requerimiento_adicional}
																			</Typography>
																		</Grid>
																		
																		<Grid item className={'w-50-px'}>
																			<Input placeholder="0.00"
																			       className={'px-14 text-right'}
																			       inputProps={{
																				       'aria-label': 'Costo adicional',
																			       }}
																			       value={item.costo_adicional}
																			       name={'costo_adicional__' + key}
																			       onChange={(e) => this.props.handleChange(e, 'producto_requerimiento_adicional', 'costo_adicional')}
																			       disabled={disabledInputVentaEditar}
																			/>
																		</Grid>
																	
																	</Grid>
																
																</Grid>
															))}
															
															{/*{!disabledInputVentaEditar ? (
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
																	
																	<div className={'row-flex'}>
																		<div className="w-100-40-px">
																			<TextField
																				fullWidth
																				type="text"
																				margin="none"
																				className={classes.root}
																				inputProps={{
																					className: classes.input,
																					placeholder: "Otros requerimientos",
																				}}
																				
																				name={'_otro_requerimiento'}
																				onChange={this.props.handleChange}
																				value={this.props.form._otro_requerimiento}
																				disabled={disabledInputVentaEditar}
																			/>
																		</div>
																		<div className="w-40-px vertical-inline">
																			<div className={'w-100 v-end'}
																			     align={'right'}>
																				<div
																					className={'w-30-px h-30-px b-r-100 bg-img-cover-x-center-y-center cursor-pointer'}
																					onClick={this.props.agregar_producto_requerimiento_adicional}
																					style={btn_agregar_producto_requerimiento_adicional}/>
																			</div>
																		</div>
																	</div>
																
																</Grid>
															) : ''}*/}
															
															{!disabledInputVentaEditar ? (
																<Grid item xs={12} sm={12} md={12} lg={12} xl={12}
																      align={'center'} className={'padding-15-T'}>
																	<Button variant="contained" color="primary"
																	        onClick={this.props.mas}
																	        className={'btn-default-primary text-transform-none'}>
																		Agregar
																	</Button>
																</Grid>
															) : ''}
														
														</Grid>
													</Fragment>
												</div>
											
											</CardContent>
										</Card>
									</div>
								
								</Grid>
							
							</Grid>
						
						</Grid>
						
						<Grid item xs={12} sm={12} md={8} lg={8} xl={8} className={'h-100'}>
							
							<Grid container spacing={0} className={'h-100'}>
								
								{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-60-px text-left'}>
									
									<Typography variant={"h6"} component={"h6"}
									            className={'text-right text-black px-18 l-h-16'}>
										¿Como te enteraste?
									</Typography>
									
									<div align={'right'}>
										{this.props.cat_como_te_enteraste.map((item, key) => (
											<Fragment key={key}>
												<FormControlLabel
													className={'h-30-px margin-16-L margin-0-R px-14'}
													control={
														<Checkbox type="checkbox" color="primary"
														          name={'como_te_enteraste__' + key}
														          onChange={(e) => this.props.handleChange(e, 'cat_como_te_enteraste', 'checked')}
														          checked={!!item.checked}
														          disabled={disabledInputVentaEditar}
														/>
													}
													label={item.como_te_enteraste}
													labelPlacement="end"
												/>
											</Fragment>
										))}
									</div>
								
								</Grid>*/}
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'h-100-240-px'}>
									
									<article className={'borde-punteado-gray-1 bg-white'} style={{
										overflowX: "auto",
										height: "calc(100% - 15px)"
									}}>
										
										<table width={'100%'}>
											<thead>
											<tr>
												<th className={'padding-10-T padding-10-B px-14'}>Eliminar</th>
												<th className={'padding-10-T padding-10-B px-14'}>Grupo</th>
												<th className={'padding-10-T padding-10-B px-14'}>Producto</th>
												<th className={'padding-10-T padding-10-B px-14'}>Descripción</th>
												<th className={'padding-10-T padding-10-B px-14 text-center'}
												    width={75}>Cantidad
												</th>
												<th className={'padding-10-T padding-10-B px-14 text-center'}
												    width={120}>P.
													Unitario
												</th>
												<th className={'padding-10-T padding-10-B px-14 text-center'}
												    width={120}>Costo adicional
												</th>
												<th className={'padding-10-T padding-10-B px-14 text-center'}
												    width={75}>Descuento
												</th>
												<th className={'padding-10-T padding-10-B px-14 text-center'}
												    width={120}>Precio
												</th>
											</tr>
											</thead>
											<tbody>
											{this.props.productos.map((item, key) => (
												<Fragment key={key}>
													<tr className={key % 2 === 0 ? 'bg-lightgray' : 'bg-white'}>
														<td className={'padding-4'} align={'center'}>
															{!disabledInputVentaEditar ? (
																<div className={'cursor-pointer'} style={{
																	padding: '6px',
																	background: 'red',
																	height: '6px',
																	width: '6px',
																	lineHeight: '6px',
																	color: 'white',
																	borderRadius: '100%',
																	fontSize: '12px',
																}}
																     onClick={() => this.props.menos(item, key)}>x</div>
															) : ''}
														</td>
														<td className={'padding-4'}>
															<NativeSelect
																name={'id_cat_grupo__' + key}
																value={item.id_cat_grupo}
																onChange={(ev) => {
																	let data = {
																		target: {
																			value: ev.target.value,
																			name: ev.target.name,
																			checked: ev.target.checked,
																			type: ev.target.type
																		}
																	}
																	this.props.resetTipoRequerimiento(data, item, key).then((value) => {
																		if (value.r) {
																			this.props.handleChange(value.e, 'productos', 'id_cat_grupo');
																		}
																	});
																}}
																inputProps={{
																	'aria-label': 'id_cat_grupo',
																}}
																disabled={disabledInputVentaEditar}
															>
																<option value="">Selecciona un grupo
																</option>
																{this.props.cat_grupo.map(option => (
																	<option
																		key={option.id_cat_grupo}
																		value={option.id_cat_grupo}
																		children={option.grupo}
																	/>
																))}
															</NativeSelect>
														</td>
														<td className={'padding-4'}>
															<TextField
																multiline
																fullWidth
																type="text"
																margin="none"
																className={classes.root}
																inputProps={{
																	className: classes.input,
																	placeholder: "Producto"
																}}
																
																name={'venta_producto__' + key}
																onChange={(e) => this.props.handleChange(e, 'productos', 'venta_producto')}
																value={item.venta_producto}
																disabled={disabledInputVentaEditar}
															/>
														</td>
														<td className={'padding-4'}>
															<TextField
																multiline
																fullWidth
																type="text"
																margin="none"
																className={classes.root}
																inputProps={{
																	className: classes.input,
																	placeholder: "Descripción"
																}}
																
																name={'venta_descripcion__' + key}
																onChange={(e) => this.props.handleChange(e, 'productos', 'venta_descripcion')}
																value={item.venta_descripcion}
																disabled={disabledInputVentaEditar}
															/>
														</td>
														<td className={'padding-4'}>
															<TextField
																fullWidth
																type="text"
																margin="none"
																className={classes.root}
																inputProps={{
																	className: classes.input,
																	placeholder: "0"
																}}
																onKeyPress={EnteroSolo}
																
																name={'venta_cantidad__' + key}
																onChange={(e) => {
																	this.props.handleChange(e, 'productos', 'venta_cantidad');
																	this.props.updatePrecio();
																}}
																value={item.venta_cantidad}
																disabled={disabledInputVentaEditar}
															/>
														</td>
														<td className={'padding-4'}>
															<TextField
																fullWidth
																type="text"
																margin="none"
																className={classes.root}
																InputProps={{
																	inputProps: {
																		className: classes.input,
																		placeholder: "0"
																	},
																	startAdornment: <InputAdornment
																		position="start">$</InputAdornment>
																}}
																
																onKeyPress={FloatSolo}
																
																name={'venta_precio_unitario__' + key}
																onChange={(e) => {
																	this.props.handleChange(e, 'productos', 'venta_precio_unitario');
																	this.props.updatePrecio();
																}}
																value={item.venta_precio_unitario}
																disabled={disabledInputVentaEditar}
															/>
														</td>
														<td className={'padding-4 text-right'}>
															$ {FieldsJs.Currency(item.venta_costo_adicional)}
														</td>
														<td className={'padding-4 text-right'}>
															<TextField
																fullWidth
																type="text"
																margin="none"
																className={classes.root}
																InputProps={{
																	inputProps: {
																		className: classes.input,
																		placeholder: "0",
																		maxLength: 10,
																	},
																	startAdornment: <InputAdornment
																		position="start">{item.venta_tipo_descuento ? '$' : '%'}</InputAdornment>
																}}
																
																onKeyPress={(e) => {
																	MinMax(e, 0, 100);
																	EnteroSolo(e);
																}}
																name={'venta_porcentaje_descuento__' + key}
																onChange={(e) => {
																	this.props.handleChange(e, 'productos', 'venta_porcentaje_descuento');
																	this.props.updatePrecio();
																}}
																value={item.venta_porcentaje_descuento}
																disabled={disabledInputVentaEditar}
															/>
														</td>
														<td className={'padding-4 text-right'}>
															<Fragment>
																<p className={'padding-0 margin-0 px-14 ff-arial'}>
																	$ {FieldsJs.Currency(item.venta_precio)}
																</p>
																{/*{item.venta_descuento > 0 ? (
																	<p className={'padding-0 margin-0 px-9 text-blue ff-arial'}>
																		Descuento.
																		${FieldsJs.Currency(item.venta_descuento)}
																	</p>
																) : ''}*/}
															</Fragment>
														</td>
													</tr>
													<tr className={key % 2 === 0 ? 'bg-lightgray' : 'bg-white'}>
														<td className={'padding-4'} colSpan={9}>
															
															{(item.venta_producto_tipo_requerimiento || []).map((itemTR, keyTR) => (
																<div key={keyTR}
																     className={'vertical-inline b-r-20 margin-5 ' + (disabledInputVentaEditar ? 'bg-gray' : 'bg-danger')}>
																	<div
																		className={'vertical-inline padding-3 padding-10-L padding-10-R v-center text-white'}>
																		{itemTR.tipo_requerimiento || 'N/A'} {this.getAtrubutoOpcionRequerimineto(itemTR)} {itemTR.costo_adicional > 0 ? '$' + FieldsJs.Currency(itemTR.costo_adicional) : ''}
																	</div>
																	<div
																		className={'vertical-inline padding-3 padding-10-R v-center text-white'}>
																		{!disabledInputVentaEditar ? (
																			<DeleteOutlined className={'px-18'}
																			                onClick={() => this.props.eliminar_requerimiento(key, keyTR)}/>
																		) : ''}
																	</div>
																</div>
															))}
															
															{(item.venta_producto_requerimiento_adicional || []).map((itemTR, keyTR) => (
																<div key={keyTR}
																     className={'vertical-inline b-r-20 margin-5 ' + (disabledInputVentaEditar ? 'bg-gray' : 'bg-danger')}>
																	<div
																		className={'vertical-inline padding-3 padding-10-L padding-10-R v-center text-white'}>
																		{itemTR.requerimiento_adicional || 'N/A'} {itemTR.costo_adicional > 0 ? '$' + FieldsJs.Currency(itemTR.costo_adicional) : ''}
																	</div>
																	<div
																		className={'vertical-inline padding-3 padding-10-R v-center text-white'}>
																		{!disabledInputVentaEditar ? (
																			<DeleteOutlined className={'px-18'}
																			                onClick={() => this.props.eliminar_producto_atributo(key, keyTR)}/>
																		) : ''}
																	</div>
																</div>
															))}
															
															{!disabledInputVentaEditar ? (
																<ModalAtributoRroducto
																	index={key}
																	icono={(
																		<BtnMasAdd/>
																	)}
																	agregar_producto_atributo={this.props.agregar_producto_atributo}
																	showSnackBars={this.props.showSnackBars}
																/>
															) : ''}
														
														</td>
													</tr>
												</Fragment>
											))}
											{this.props.productos.length <= 0 ? (
												<tr className={'bg-white'}>
													<td className={'padding-0 text-center'} colSpan={9}>
														<VistaVacia numero={2} height={'90px'} width={'90px'}
														            padding={'5px'}
														            mensaje={'Sin productos agregados'}/>
													</td>
												</tr>
											) : null}
											<tr>
												<td className={'padding-4'} colSpan={3}></td>
												<td className={'padding-4'} colSpan={1} align={'right'}>
													<div className={'vertical-inline w-60-px h-32-px'}>
														<div className={'v-center'}>
															<span style={{color: 'white', background: this.props.form.tipo_descuento_general ? 'rgba(100,100,100,0.4)' : '#3f51b5', borderRadius: '10px 0 0 10px', padding: '5px 8px 5px 10px'}}
															      className={'px-14 cursor-pointer'} onClick={() => {
																if (!disabledInputVentaEditar) {
																	this.props.tipoDescuento(false, true)
																}
															}
															}>%</span>
														</div>
														<div className={'v-center'}>
															<span style={{color: 'white', background: !this.props.form.tipo_descuento_general ? 'rgba(100,100,100,0.4)' : '#3f51b5', borderRadius: '0 10px 10px 0', padding: '5px 10px 5px 8px'}}
															      className={'px-14 cursor-pointer'} onClick={() => {
																if (!disabledInputVentaEditar) {
																	this.props.tipoDescuento(true, true)
																}
															}}>$</span>
														</div>
													</div>
												</td>
												<td colSpan={2}>
													<TextField
														margin="none"
														name="descuento_general"
														type="text"
														fullWidth
														value={this.props.form.descuento_general}
														onChange={(e) => {
															this.props.handleChange(e);
															this.props.calcularDescuentoGeneral(e.target.value);
														}}
														inputProps={{
															placeholder: "Descuento general",
															maxLength: 50
														}}
														onKeyPress={EnteroSolo}
														disabled={disabledInputVentaEditar}
													/>
												</td>
												<td className={'padding-4'} colSpan={2}>Suma</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.suma)}
												</td>
											</tr>
											<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={6}></td>
												<td className={'padding-4'} colSpan={2}>Descuento</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.descuento)}
												</td>
											</tr>
											<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={6}></td>
												<td className={'padding-4'} colSpan={2}>Subtotal</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.subtotal)}
												</td>
											</tr>
											<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={6}></td>
												<td className={'padding-4'} colSpan={2}>IVA {desglose.iva} %</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.iva_total)}
												</td>
											</tr>
											{/*<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={6}></td>
												<td className={'padding-4'} colSpan={2}>Descuento</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.descuento)}
												</td>
											</tr>*/}
											{/*<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={6}></td>
												<td className={'padding-4'} colSpan={2}>Total sin descuento</td>
												<td className={'padding-4 text-right'}>
													$ {FieldsJs.Currency(desglose.total)}
													<hr/>
												</td>
											</tr>*/}
											<tr className={'bg-white'}>
												<td className={'padding-4'} colSpan={6}></td>
												<td className={'padding-4'} colSpan={2}>TOTAL</td>
												<td className={'padding-4 text-right'}>
													{/*$ {FieldsJs.Currency(desglose.total - desglose.descuento)}*/}
													$ {FieldsJs.Currency(desglose.total)}
												</td>
											</tr>
											</tbody>
										</table>
									
									</article>
								
								</Grid>
								
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}
								      className={'h-180-px borde-punteado-gray-1 bg-lightgray padding-10'}>
									
									<Grid container spacing={2} justify="flex-end" alignItems="flex-end">
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'text-center'}>
											<Grid container spacing={0}>
												{/*<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
													<Typography variant={'h6'} className={'text-black px-10'}>
														Agrega los insumos de la orden de venta
													</Typography>
												</Grid>*/}
												<Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
													<Typography variant={'h6'} className={'text-black px-10'}>
														Todo trabajo requiere un 50 % de anticipo
													</Typography>
												</Grid>
												<Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
													<Typography variant={'h6'} className={'text-black px-10'}>
														Precios sujetos a cambio sin previo aviso
													</Typography>
												</Grid>
												<Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
													<Typography variant={'h6'} className={'text-black px-10'}>
														¡DISEÑAMOS AL GUSTO DE NUESTROS CLIENTES!
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{padding: 0}}>
											<Divider/>
										</Grid>
										
										{/*{!disabledInputVentaEditar ? (
											<Grid item xs={12} sm={3} md={3} lg={3} xl={3} className={'text-left'}>
												<TextField
													rows={4}
													inputProps={{
														style: {
															fontSize: '13px',
															lineHeight: '13px',
															padding: '0px',
															margin: '0px',
														}
													}}
													className={'px-12 padding-0 margin-0'}
													variant={"filled"}
													multiline
													fullWidth
													onChange={this.props.handleChange}
													type="text"
													margin="none"
													helperText=""
													name="insumo"
													label="Insumos"
													value={this.props.form.insumo}
													disabled={disabledInputVentaEditar}
												/>
											</Grid>
										) : ''}*/}
										
										<Grid item className={'text-left'}
										      xs={12}
										      sm={8}
										      md={8}
										      lg={8}
										      xl={8}>
											<TextField
												rows={4}
												inputProps={{
													style: {
														fontSize: '13px',
														lineHeight: '13px',
														padding: '0px',
														margin: '0px',
													}
												}}
												className={'px-12 padding-0 margin-0'}
												variant={"filled"}
												multiline
												fullWidth
												onChange={this.props.handleChange}
												type="text"
												margin="none"
												
												helperText=""
												name="observaciones"
												label="Observaciones"
												value={this.props.form.observaciones}
												disabled={disabledInputVentaEditar}
											/>
										</Grid>
										
										{/*<Grid item className={'text-left'}
										      xs={12}
										      sm={2}
										      md={2}
										      lg={2}
										      xl={2}>
											
											<FormGroup row>
												<FormControlLabel
													className={'h-50-px px-11 text-left l-h-12-px'}
													control={
														<Checkbox
															fontSize="small"
															type="checkbox"
															color="primary"
															name="cliente_deja_anexo"
															onChange={(e) => this.props.handleChange(e)}
															checked={!!this.props.form.cliente_deja_anexo}
															disabled={disabledInputVentaEditar}
														/>
													}
													label="Cliente deja anexo imagen de propuesta"
													labelPlacement="end"
												/>
											</FormGroup>
											
											<FormGroup row>
												<FormControlLabel
													className={'h-50-px px-11 text-left l-h-12-px'}
													control={
														<Checkbox
															fontSize="small"
															type="checkbox"
															color="primary"
															name="cliente_envia_muestra_por_correo"
															onChange={(e) => this.props.handleChange(e)}
															checked={!!this.props.form.cliente_envia_muestra_por_correo}
															disabled={disabledInputVentaEditar}
														/>
													}
													label="Cliente envía muestra por correo electrónico"
													labelPlacement="end"
												/>
											</FormGroup>
										
										</Grid>*/}
										
										<Grid item xs={12} sm={4} md={4} lg={4} xl={4} className={'text-left'}>
											
											<Grid container spacing={2} justify="flex-end" alignItems="flex-end">
												
												<Grid item xs={5} sm={5} md={5} lg={5} xl={5}
												      className={'text-left'}>
													<TextField
														multiline
														fullWidth
														type="text"
														margin="none"
														className={classes.root}
														inputProps={{
															className: classes.input,
															placeholder: "0",
															maxLength: 10
														}}
														onKeyPress={(e) => {
															MinMax(e, 0, 100);
															EnteroSolo(e);
														}}
														
														label=""
														helperText={'% descuento'}
														name={'porcentaje_descuento'}
														value={this.props.form.porcentaje_descuento}
														onChange={(e) => this.props.handleChange(e)}
														// disabled={disabledInputVentaEditar}
														disabled={true}
													/>
												</Grid>
												
												<Grid item xs={7} sm={7} md={7} lg={7} xl={7}
												      className={'text-left'}>
													<KeyboardDatePicker
														format={'dd/MM/yyyy'} fullWidth required clearable
														inputProps={{readOnly: true}}
														KeyboardButtonProps={{
															'aria-label': 'change date',
															disabled: disabledInputVentaEditar
														}}
														label=""
														helperText={'Fecha entrega'}
														value={this.props.form.fecha_entrega}
														onChange={(date) => {
															this.props.handleChange(null, null, null, date, 'fecha_entrega');
														}}
														clearLabel={'Limpiar'}
														okLabel={'Aceptar'}
														cancelLabel={'Cancelar'}
														disabled={disabledInputVentaEditar}
													/>
												</Grid>
												
												{!disabledInputVentaVer ? (
													<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={"right"}>
														<Button
															fullWidth
															variant="contained"
															onClick={() => {
																let smg = "";
																if (!disabledInputVentaEditar && !disabledInputVenta === false) {
																	smg = "¿Estas seguro de salir de la modificación de la venta, se perderán los cambios realizados?";
																} else {
																	if (!disabledInputVenta) {
																		smg = "¿Estas seguro de salir de la configuración de la venta, se perderán los datos capturados?";
																	} else {
																		smg = "¿Deseas salir de la vista previa de la orden de venta?";
																	}
																}
																PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, smg).then((r) => {
																	if (r.button === 'Aceptar') {
																		window.history.back();
																	}
																});
															}}
															style={{
																backgroundColor: "white",
																color: "black",
																textTransform: "none",
																padding: "7px 0px",
																fontSize: "13px",
															}}
														>
															
															<Fragment>
																{!disabledInputVentaEditar && !disabledInputVenta === false ? "Atras" : (!disabledInputVenta ? "Cancelar orden" : "Regresar")}
															</Fragment>
														
														</Button>
													</Grid>
												) : null}
												
												{!disabledInputVentaEditar && !disabledInputVenta === false ? (
													<Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={"right"}>
														<Button
															fullWidth
															variant="contained"
															onClick={this.props.OrdenDeVentaActualizar}
															style={{
																backgroundColor: "#595959",
																color: "white",
																textTransform: "none",
																padding: "4px 0px",
																fontSize: "16px",
															}}
														>
															Actualizar
														</Button>
													</Grid>
												) : (
													<Fragment>
														{!disabledInputVenta ? (
															<Grid item xs={6} sm={6} md={6} lg={6} xl={6}
															      align={"right"}>
																<Button
																	fullWidth
																	variant="contained"
																	onClick={this.props.OrdenDeVentaContinuar}
																	style={{
																		backgroundColor: "#595959",
																		color: "white",
																		textTransform: "none",
																		padding: "4px 0px",
																		fontSize: "16px",
																	}}
																>
																	Continuar
																</Button>
															</Grid>
														) : null}
													</Fragment>
												)}
											
											</Grid>
										
										</Grid>
									
									</Grid>
								
								</Grid>
							
							</Grid>
						
						</Grid>
					
					</Grid>
				
				</section>
			
			</Fragment>
		);
	}
}

ContenidoOrdenVenta.propTypes = {
	OrdenDeVentaDatos: PropTypes.func,
	classes: PropTypes.object.isRequired,
	RefreshListCliente: PropTypes.func.isRequired,
	RefreshListClienteFactura: PropTypes.func.isRequired,
	
	handleChange: PropTypes.func.isRequired,
	updatePrecio: PropTypes.func.isRequired,
	
	mas: PropTypes.func.isRequired,
	menos: PropTypes.func.isRequired,
	
	eliminar_requerimiento: PropTypes.func.isRequired,
	eliminar_producto_atributo: PropTypes.func.isRequired,
	agregar_producto_atributo: PropTypes.func.isRequired,
	
	producto_requerimiento_adicional: PropTypes.array.isRequired,
	
	agregar_producto_requerimiento_adicional: PropTypes.func.isRequired,
	eliminar_producto_requerimiento_adicional: PropTypes.func.isRequired,
	
	cat_como_te_enteraste: PropTypes.array.isRequired,
	cat_tipo_requerimiento: PropTypes.array.isRequired,
	productos: PropTypes.array.isRequired,
	cat_estado: PropTypes.array.isRequired,
	cat_municipio: PropTypes.array.isRequired,
	cat_grupo: PropTypes.array.isRequired,
	cat_envio_estatus: PropTypes.array.isRequired,
	cat_tipo_mensajeria: PropTypes.array.isRequired,
	
	suggestions: PropTypes.array.isRequired,
	handleChangeSingle: PropTypes.func.isRequired,
	updateDesglose: PropTypes.func.isRequired,
	getCatTipoRequerimiento: PropTypes.func.isRequired,
	resetTipoRequerimiento: PropTypes.func.isRequired,
	apply_search: PropTypes.func.isRequired,
	
	OrdenDeVentaContinuar: PropTypes.func.isRequired,
	OrdenDeVentaGuardar: PropTypes.func.isRequired,
	OrdenDeVentaActualizar: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	actualizarProductosPaquetes: PropTypes.func.isRequired,
	selectProducto: PropTypes.func.isRequired,
	selectEnvio: PropTypes.func.isRequired,
	calcularDescuentoGeneral: PropTypes.func.isRequired,
	tipoDescuento: PropTypes.func.isRequired,
	id_cat_tipo_usuario: PropTypes.number
	
};

export default withStyles(styles)(ContenidoOrdenVenta);
